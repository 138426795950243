<template>
  <div>
    <!--
    <div v-if="!facilityLevel">
      <span v-if="editMode" class="ml-6"
        >Details, rules, and fees involved with a tenant's move-in/transfer
        process.</span
      >
      <span v-else class="ml-6"
        ><span class="font-weight-medium">Step 2: </span>Provide the details,
        rules, and fees involved with a tenant's move-in/transfer process.</span
      >
      <v-divider class="mt-3"></v-divider>
    </div>
    -->

    <hb-form
      label="Lease Period"
      :description="active === 'Lease Period' || (!editMode && !readMode) ? 'Choose if this is a month-to-month agreement or set for a fixed time.' : undefined"
      :required="!readMode"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active.sync="active"
      :empty="!template.lease_type && !readMode"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      :auto-layout="false"
      full
    >
      <template v-slot:display>
        <div>{{ template.lease_type }}</div>
        <div v-if="template.lease_type == leaseTypes.fixedLength.value" class="mt-2">
          Duration: {{ template.lease_duration && template.lease_duration === 1 ? template.lease_duration + ' ' + template.lease_duration_type.substring(0, template.lease_duration_type.length - 1) : template.lease_duration + ' ' + template.lease_duration_type }}
        </div>
      </template>
      <template v-slot:edit>
        <hb-select
          v-model="template.lease_type"
          v-validate="'required'"
          :clearable="false"
          :items="Object.values(leaseTypes)"
          id="lease_type"
          name="lease_type"
          data-vv-scope="form_2"
          data-vv-as="Lease Type"
          :error="errors.has('form_2.lease_type')"
          placeholder="Select Lease Type"
        ></hb-select>

        <v-row v-if="template.lease_type == leaseTypes.fixedLength.value" no-gutters class="mt-3 mb-n3">
          <div class="hb-inline-sentence-text">
            Duration:
          </div>
          <HbTextField
            condensed
            box
            x-small
            v-model="template.lease_duration"
            v-validate="
              `${
                template.lease_type == leaseTypes.fixedLength.value ? 'required' : ''
              }|integer|min_value:1`
            "
            name="lease_duration"
            id="lease_duration"
            data-vv-scope="form_2"
            data-vv-as="Lease Duration"
            :error="errors.has('form_2.lease_duration')"
            placeholder="0"
          />

          <HbSelect
            box
            condensed
            medium
            v-model="template.lease_duration_type"
            :items="Object.values(lease_duration_types)"
            v-validate="'required'"
            id="lease_duration_types"
            name="lease_duration_types"
            data-vv-scope="form_2"
            data-vv-as="Lease Duration Type"
            :error="errors.has('form_2.lease_duration_types')"
            placeholder="Select"
          />
        </v-row>
      </template>
    </hb-form>

    <hb-form
      label="Bill Tenant On"
      :description="active === 'Bill Tenant On' || (!editMode && !readMode) ? 'Select 1st of the month or Anniversary.' : undefined"
      :required="!readMode"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active.sync="active"
      :empty="!template.bill_day"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      full
      :auto-layout="false"
    >
      <template v-slot:display>
        <div>{{ template.bill_day }}</div>
        <div class="mt-2">An invoice is created {{ template.invoiceSendDay }} day(s) before the due date.</div>
        <hb-checkbox
          v-model="template.email_statement"
          label="Send Invoice Emails"
          readonly
          class="pt-2"
        >
        </hb-checkbox>
        <hb-checkbox
          v-if="false"
          v-model="template.email_consolidated_statement"
          :label="'Send Consolidated Statement'"
          readonly
          class="pt-2"
        >
        </hb-checkbox>
      </template>
      <template v-slot:edit>
        <hb-select
          v-model="template.bill_day"
          v-validate="'required'"
          :clearable="false"
          :items="Object.values(bill_on_options)"
          id="bill_day"
          name="bill_day"
          data-vv-scope="form_2"
          data-vv-as="Bill Day"
          :error="errors.has('form_2.bill_day')"
          placeholder="Select Bill Day"
        ></hb-select>

        <v-row no-gutters class="mt-3">
          <div class="hb-inline-sentence-text">
            An invoice is created
          </div>

          <HbSelect
            box
            condensed
            small
            v-model="template.invoiceSendDay"
            :items="invoiceDays"
            :clearable="false"
            v-validate="'required'"
            id="invoiceSendDay"
            name="invoiceSendDay"
            data-vv-scope="form_2"
            data-vv-as="Invoice Send Day"
            :error="errors.has('form_2.invoiceSendDay')"
          />

          <div class="hb-inline-sentence-text">
            day(s) before the due date.
          </div>

        </v-row>

        <hb-checkbox
          v-model="template.email_statement"
          label="Send Invoice Emails"
          class="mt-n2"
        >
        </hb-checkbox>
        <hb-checkbox
          v-if="false"
          v-model="template.email_consolidated_statement"
          @input="handleInvoiceStatementFlag"
          label="Send Consolidated Statement"
          class="mt-2"
        >
        </hb-checkbox>
      </template>
    </hb-form>

    <hb-form
      label="Rental Settings"
      :description="active === 'Rental Settings' || (!editMode && !readMode) ? 'Choose the rental settings you want to include in this lease.' : undefined"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active.sync="active"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      full
      :auto-layout="false"
    >
      <template v-slot:display>
        <hb-checkbox
          v-model="template.prorate_rent"
          label="Prorate monthly charges on move-in"
          readonly
        >
        </hb-checkbox>

        <hb-checkbox
          v-model="template.security_deposit"
          readonly
          class="mt-3"
        >
          <template v-slot:label>
            Require a Security Deposit{{ template.security_deposit ? ' of' : ''}} {{ depositOption === '$' ? depositOption : '' }}{{ template.deposit_amount }} {{ depositOption !== '$' ? depositOption : '' }}
          </template>
        </hb-checkbox>

        <hb-checkbox
          v-model="template.add_next_month"
          readonly
          class="mt-3"
        >
          <template v-slot:label>
            Add charges for next month after {{ template.add_next_month_days}} days
          </template>
        </hb-checkbox>

        <hb-checkbox
          v-model="template.auto_pay"
          label="Require AutoPay Enrollment"
          readonly
          class="mt-3"
        >
        </hb-checkbox>
      </template>
      <template v-slot:edit>
        <hb-checkbox
          v-model="template.prorate_rent"
          label="Prorate monthly charges on move-in"
          id="prorate_monthly_charges"
          name="prorate_monthly_charges"
        >
        </hb-checkbox>

        <hb-checkbox
          v-model="template.security_deposit"
          id="security_deposit"
          name="security_deposit"
          align-top
          class="mt-3"
        >
          <template v-slot:content>
            <v-row no-gutters class="mt-n3">
              <div class="hb-inline-sentence-text">
                Require a Security Deposit of
              </div>

              <HbSelect
                box
                condensed
                small
                v-model="template.security_deposit_type"
                v-validate="`${template.security_deposit ? 'required' : ''}`"
                :items="Object.values(deposit_options)"
                id="security_deposit_type"
                name="security_deposit_type"
                data-vv-as="Security Desposit Type"
                data-vv-scope="form_2"
                :error="errors.has('form_2.security_deposit_type')"
              />

              <HbTextField
                box
                condensed
                small
                v-model="template.deposit_amount"
                v-validate="
                  `${template.security_deposit ? 'required' : ''}|max:45`
                "
                id="deposit_amount"
                name="deposit_amount"
                data-vv-as="Security Desposit Amount"
                data-vv-scope="form_2"
                :error="errors.has('form_2.deposit_amount')"
                placeholder="0"
              />
            </v-row>
          </template>
        </hb-checkbox>

        <hb-checkbox
          v-model="template.add_next_month"
          align-top
          class="mt-3"
        >
          <template v-slot:content>
            <v-row no-gutters class="mt-n3">
              <div class="hb-inline-sentence-text">
                Add charges for next month after
              </div>
              <HbSelect
                box
                condensed
                small
                v-model="template.add_next_month_days"
                :items="invoiceDays"
                :clearable="false"
                v-validate="template.add_next_month ? 'required' : ''"
                id="add_next_month_days"
                name="add_next_month_days"
                data-vv-scope="form_2"
                data-vv-as="Extra Month"
                :error="errors.has('form_2.add_next_month_days')"
                :disabled="!template.add_next_month"
              />
              <div class="hb-inline-sentence-text">
                days
              </div>
            </v-row>
          </template>
        </hb-checkbox>

        <hb-checkbox
          v-model="template.auto_pay"
          label="Require AutoPay Enrollment"
          id="autopay_enrollment"
          name="autopay_enrollment"
          :class="{ 'pt-3' : $vuetify.breakpoint.xs }"
        >
        </hb-checkbox>
      </template>
    </hb-form>

    <hb-form
      label="AutoPay"
      :required="!readMode"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :active.sync="active"
      :empty="!template.lease_type"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      :auto-layout="false"
      full
    >
      <template v-slot:display>
        <div>AutoPay will run {{ (template.auto_pay_after_billing_date == 1) ? template.auto_pay_after_billing_date + '-Delinquent' : template.auto_pay_after_billing_date }} days after the billing date.</div>
        <div class="mt-3">AutoPay will run a maximum of {{ template.auto_pay_max_times ? template.auto_pay_max_times : 0 }} times.</div>
      </template>
      <template v-slot:edit>
        <v-row no-gutters>
          <div class="hb-inline-sentence-text">
            AutoPay will run
          </div>

          <hb-select
            condensed
            box
            small
            v-model="template.auto_pay_after_billing_date"
            v-validate="'required'"
            :items="invoiceDays"
            id="auto_pay_after_billing_date"
            name="auto_pay_after_billing_date"
            data-vv-scope="form_2"
            data-vv-as="AutoPay Day"
            :error="errors.has('form_2.auto_pay_after_billing_date')"
          >
            <template v-slot:selection="data">
              <div v-if="data.item == 1" class="hb-overflow-handler" style="padding-top:3px;">{{data.item}}-Delinquent</div>
              <div v-else style="padding-top:3px;">{{data.item}}</div>
            </template>
            <template v-slot:item="data">
              <div v-if="data.item == 1" class="hb-overflow-handler">{{data.item}}-Delinquent</div>
              <div v-else>{{data.item}}</div>
            </template>
          </hb-select>

          <div class="hb-inline-sentence-text">
            days after the billing date.
          </div>
        </v-row>

        <v-row no-gutters class="mb-n3">
          <div class="hb-inline-sentence-text">
            AutoPay will run a maximum of
          </div>

          <HbSelect
            condensed
            box
            small
            v-model="template.auto_pay_max_times"
            v-validate="'required'"
            :items="invoiceDays"
            id="auto_pay_max_times"
            name="auto_pay_max_times"
            data-vv-scope="form_2"
            data-vv-as="AutoPay Run Max Times"
            :error="errors.has('form_2.auto_pay_max_times')"
          />

          <div class="hb-inline-sentence-text">
            times.
          </div>
        </v-row>
      </template>
    </hb-form>

    <!--
    <v-row class="mr-0 pl-3">
      <v-col class="field-label pb-0 pr-0 pt-2 pl-4" cols="3">
        <div>
          <span class="font-weight-medium">Lease Period*</span>
        </div>
      </v-col>
      <v-col cols="9" class="ma-0 pa-0">
        <hoverable-edit-field
          :activeEditing="activelyEditing == 'lease_type'"
          :hasPermissionToEdit="isPermissionToEdit"
          name="lease_type"
        >
          <template slot="editingMode">
            <v-row class="ma-0 pa-0">
              <v-col class="pa-0 field-description" cols="12">
                Choose if this is a month-to-month agreement or set for a fixed
                time.
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="pa-0" cols="6">
                <v-select
                  :error-messages="errors.first('form_2.lease_type')"
                  :items="Object.values(leaseTypes)"
                  attach
                  hide-details
                  single-line
                  label="Select Lease Type"
                  id="lease_type"
                  name="lease_type"
                  data-vv-as="lease type"
                  data-vv-scope="form_2"
                  v-validate="'required'"
                  v-model="template.lease_type"
                  class="pt-0"
                  :class="{
                    'custom-field-error': errors.first('form_2.lease_type'),
                  }"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0 pb-2" v-if="template.lease_type == leaseTypes.fixedLength.value">
              <v-col class="pa-0 pr-2" cols="2">
                <v-text-field
                  single-line
                  :error-messages="errors.first('form_2.lease_duration')"
                  hide-details
                  v-validate="
                    `${
                      template.lease_type == leaseTypes.fixedLength.value ? 'required' : ''
                    }|integer|min_value:1`
                  "
                  name="lease_duration"
                  id="lease_duration"
                  data-vv-as="lease duration"
                  v-model="template.lease_duration"
                  data-vv-scope="form_2"
                  class="pt-1 ma-0"
                  :class="{
                    'custom-field-error': errors.first('form_2.lease_duration'),
                  }"
                ></v-text-field>
              </v-col>
              <v-col class="pa-0" cols="3">
                <v-select
                  :error-messages="errors.first('form_2.lease_duration_types')"
                  :items="Object.values(lease_duration_types)"
                  attach
                  hide-details
                  single-line
                  label="Select"
                  id="lease_duration_types"
                  name="lease_duration_types"
                  data-vv-as="lease duration type"
                  data-vv-scope="form_2"
                  v-validate="'required'"
                  v-model="template.lease_duration_type"
                  class="pt-0"
                  :class="{
                    'custom-field-error': errors.first(
                      'form_2.lease_duration_types'
                    ),
                  }"
                >
                </v-select>
              </v-col>
            </v-row>
          </template>
          <template slot="readMode" v-if="editMode">
            <v-row class="pa-0 ma-0">
              <v-col class="pa-0 ma-0" cols="12">
                {{ template.lease_type }}
              </v-col>
            </v-row>
          </template>
        </hoverable-edit-field>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row class="mr-0 pl-3">
      <v-col class="field-label pb-0 pr-0 pt-2 pl-4" cols="3">
        <div>
          <span class="font-weight-medium">Bill Tenant On*</span>
        </div>
      </v-col>
      <v-col cols="9" class="ma-0 pa-0">
        <hoverable-edit-field
          :activeEditing="activelyEditing == 'bill_day'"
          :hasPermissionToEdit="isPermissionToEdit"
          name="bill_day"
        >
          <template slot="editingMode">
            <v-row class="ma-0 pa-0">
              <v-col class="pa-0 field-description" cols="12">
                Select 1st of the month or Anniversary.
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="pa-0" cols="6">
                <v-select
                  :error-messages="errors.first('form_2.bill_day')"
                  :items="Object.values(bill_on_options)"
                  attach
                  v-model="template.bill_day"
                  hide-details
                  single-line
                  label="Select"
                  id="bill_day"
                  name="bill_day"
                  data-vv-scope="form_2"
                  class="pt-0"
                  :class="{
                    'custom-field-error': errors.first('form_2.bill_day'),
                  }"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="pa-0 pt-2" cols="4" style="max-width: 10em;"> An invoice is created </v-col>
              <v-col class="pa-0 pl-2" cols="3" style="max-width: 8em;">
                <v-select
                  :error-messages="errors.first('form_2.invoiceSendDay')"
                  :items="invoiceDays"
                  hide-details
                  attach
                  single-line
                  label="Select"
                  id="invoiceSendDay"
                  name="invoiceSendDay"
                  data-vv-as="days"
                  data-vv-scope="form_2"
                  v-validate="'required'"
                  v-model="template.invoiceSendDay"
                  class="pt-0 mt-0"
                  :class="{
                    'custom-field-error': errors.first('form_2.invoiceSendDay'),
                  }"
                >
                  <template v-slot:selection="{ item }">
                    {{ item | capitalize }}
                  </template>
                  
                </v-select>
              </v-col>
              <v-col class="pa-0 pt-2 pl-2" cols="5">
                day(s) before the due date.
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="pa-0" cols="5">
                <v-checkbox
                  hide-details
                  v-model="template.email_statement"
                  id="email_statement"
                  name="email_statement"
                  class="mt-1"
                >
                  <template v-slot:label>
                    <span class="hb-text-night">Email monthly invoice</span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </template>
          <template slot="readMode" v-if="editMode">
            <v-row class="pa-0 ma-0">
              <v-col class="pa-0 ma-0" cols="12">
                {{ template.bill_day }}
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0 pt-2">
              <v-col class="pa-0 ma-0" cols="12">
                An invoice is created {{ template.invoiceSendDay }} day(s) before
                the due date.
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0 pt-2">
              <v-col class="pa-0 ma-0" cols="12">
                <v-icon class="pr-2" v-if="template.email_statement" color="#101318"
                  >mdi-check</v-icon
                >
                {{ facilityLevel ? 'Send monthly statement' : 'Email monthly invoice' }}
              </v-col>
            </v-row>
          </template>
        </hoverable-edit-field>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    
    <v-row class="mr-0 pl-3">
      <v-col class="field-label pb-0 pr-0 pt-2 pl-4" cols="3">
        <div>
          <span class="font-weight-medium">Rental Settings</span>
        </div>
      </v-col>
      <v-col cols="9" class="ma-0 pa-0">
        <hoverable-edit-field
          :activeEditing="activelyEditing == 'rental_settings'"
          :hasPermissionToEdit="isPermissionToEdit"
          name="rental_settings"
        >
          <template slot="editingMode">
            <v-row class="ma-0 pa-0">
              <v-col class="pa-0 field-description" cols="12">
                Choose the rental settings you want to include in this lease.
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="pa-0 pt-0" cols="7">
                <v-checkbox
                  hide-details
                  v-model="template.prorate_rent"
                  id="prorate_monthly_charges"
                  name="prorate_monthly_charges"
                  class="mt-1"
                >
                  <template v-slot:label>
                    <span class="hb-text-night">Prorate monthly charges on move-in</span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="pa-0" cols="5" style="max-width: 14em;">
                <v-checkbox
                  hide-details
                  v-model="template.security_deposit"
                  id="security_deposit"
                  name="security_deposit"
                  class="mt-1"
                >
                  <template v-slot:label>
                    <span class="hb-text-night">Require Security Deposit</span>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col class="pa-0 px-2" cols="4">
                <v-select
                  :error-messages="errors.first('form_2.security_deposit_type')"
                  :items="Object.values(deposit_options)"
                  attach
                  hide-details
                  single-line
                  label="Select Type"
                  id="security_deposit_type"
                  name="security_deposit_type"
                  data-vv-as="security desposit type"
                  data-vv-scope="form_2"
                  v-validate="`${template.security_deposit ? 'required' : ''}`"
                  v-model="template.security_deposit_type"
                  class="pt-0"
                  :class="{
                    'custom-field-error': errors.first('form_2.security_deposit_type'),
                  }"
                >
                </v-select>
              </v-col>
              <v-col class="pa-0" cols="2">
                <v-text-field
                  single-line
                  :error-messages="errors.first('form_2.deposit_amount')"
                  hide-details
                  v-model="template.deposit_amount"
                  label="Amount"
                  id="deposit_amount"
                  name="deposit_amount"
                  v-validate="
                    `${template.security_deposit ? 'required' : ''}|max:45`
                  "
                  data-vv-as="desposit amount"
                  data-vv-scope="form_2"
                  class="pt-1 ma-0"
                  :class="{
                    'custom-field-error': errors.first('form_2.deposit_amount'),
                  }"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="pa-0" cols="6">
                <v-checkbox
                  hide-details
                  v-model="template.auto_pay"
                  id="autopay_enrollment"
                  name="autopay_enrollment"
                  class="mt-1"
                >
                  <template v-slot:label>
                    <span class="hb-text-night">Require AutoPay Enrollment</span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </template>
          <template slot="readMode" v-if="editMode">
            <v-row class="pa-0 ma-0 pb-5" v-if="!facilityLevel">
              <v-col class="pa-0 ma-0" cols="12">
                Listed are the rental settings you want to include in this
                lease.
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
              <v-col class="pa-0 ma-0" cols="12">
                <v-icon class="pr-2" v-if="template.prorate_rent" color="#101318"
                  >mdi-check</v-icon
                >
                Prorate monthly charges on move-in
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0 pt-2">
              <v-col class="pa-0 ma-0" cols="12">
                <v-icon class="pr-2" v-if="template.security_deposit" color="#101318"
                  >mdi-check</v-icon
                >
                Require a Security Deposit of {{template.deposit_amount}} {{ depositOption }}
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0 pt-2">
              <v-col class="pa-0 ma-0" cols="12">
                <v-icon class="pr-2" v-if="template.auto_pay" color="#101318"
                  >mdi-check</v-icon
                >
                Require AutoPay Enrollment
              </v-col>
            </v-row>
          </template>
        </hoverable-edit-field>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="mr-0 pl-3">
      <v-col class="field-label pb-0 pr-0 pt-2 pl-4" cols="3">
        <div>
          <span class="font-weight-medium">AutoPay*</span>
        </div>
      </v-col>
      <v-col cols="9" class="ma-0 pa-0">
        <hoverable-edit-field
          :activeEditing="activelyEditing == 'autopay'"
          :hasPermissionToEdit="isPermissionToEdit"
          name="autopay"
        >
          <template slot="editingMode">
            <v-row class="ma-0 pa-0">
              <v-col class="pa-0 pt-2" cols="4" style="max-width: 8em;">AutoPay will run</v-col>
              <v-col class="pa-0 pl-2" cols="4" style="max-width: 10em;">
                <v-select
                  :error-messages="errors.first('form_2.auto_pay_after_billing_date')"
                  :items="invoiceDays"
                  hide-details
                  attach
                  single-line
                  label="Select"
                  id="auto_pay_after_billing_date"
                  name="auto_pay_after_billing_date"
                  data-vv-as="days"
                  data-vv-scope="form_2"
                  v-validate="'required'"
                  v-model="template.auto_pay_after_billing_date"
                  class="pt-0 mt-0"
                  :class="{
                    'custom-field-error': errors.first('form_2.auto_pay_after_billing_date'),
                  }"
                >
                  <template v-slot:selection="data">
                    <span v-if="data.item == 1">{{data.item}}-Delinquent </span>
                    <span v-else>{{data.item}}</span>
                  </template>
                  <template v-slot:item="data">
                    <span v-if="data.item == 1">{{data.item}}-Delinquent </span>
                    <span v-else>{{data.item}}</span>
                  </template>
                </v-select>
              </v-col>
              <v-col class="pa-0 pt-2 pl-2"> days after the billing date. </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="pa-0 pt-2" cols="6" style="max-width: 15em;"> AutoPay will run a maximum of </v-col>
              <v-col class="pa-0 pl-2" cols="3" style="max-width: 8em;">
                <v-select
                  :error-messages="errors.first('form_2.auto_pay_max_times')"
                  :items="invoiceDays"
                  hide-details
                  attach
                  single-line
                  label="Select"
                  id="auto_pay_max_times"
                  name="auto_pay_max_times"
                  data-vv-as="time"
                  data-vv-scope="form_2"
                  v-validate="'required'"
                  v-model="template.auto_pay_max_times"
                  class="pt-0 mt-0"
                  :class="{
                    'custom-field-error': errors.first('form_2.auto_pay_max_times'),
                  }"
                ></v-select>
              </v-col>
              <v-col class="pa-0 pt-2 pl-2" cols="3"> times. </v-col>
            </v-row>
          </template>
          <template slot="readMode" v-if="editMode">
            <v-row class="pa-0 ma-0 pt-2">
              <v-col class="pa-0 ma-0" cols="12">
                AutoPay will run {{ (template.auto_pay_after_billing_date == 1) ? template.auto_pay_after_billing_date + '-Delinquent' : template.auto_pay_after_billing_date }} days after the billing date.
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0 pt-2">
              <v-col class="pa-0 ma-0" cols="12">
                AutoPay will run a maximum of {{ template.auto_pay_max_times ? template.auto_pay_max_times : 0 }} times.
              </v-col>
            </v-row>
          </template>
        </hoverable-edit-field>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    -->

    <!--Added by BCT: Suppress document-library field from OPS -->
    <tenant-rental-document-library v-if="!isOPS" ref="TenantRentalDocumentLibrary" :editMode="editMode" :readMode="readMode" :activelyEditing="activelyEditing" :leaseTemplate.sync="template" :facilityLevel="facilityLevel" :active.sync="active" :active-tab="activeTab" @setActive="handleSetActive" @save="handleEmittedSave" @cancel="handleEmittedCancel" />
    <move-in-fees :leaseTemplate.sync="template" :editMode="editMode" :readMode="readMode" :activelyEditing="activelyEditing" :facilityLevel="facilityLevel" :active.sync="active" @setActive="handleSetActive" @save="handleEmittedSave" @cancel="handleEmittedCancel" />
    <transfer-fees :leaseTemplate.sync="template" :editMode="editMode" :readMode="readMode" :activelyEditing="activelyEditing" :facilityLevel="facilityLevel" :active.sync="active" @setActive="handleSetActive" @save="handleEmittedSave" @cancel="handleEmittedCancel" />
    <add-merchandise :leaseTemplate.sync="template" :editMode="editMode" :readMode="readMode" :activelyEditing="activelyEditing" :facilityLevel="facilityLevel" :active.sync="active" @setActive="handleSetActive" @save="handleEmittedSave" @cancel="handleEmittedCancel" />
    <move-in-check-list ref="MoveInChecklist" :leaseTemplate.sync="template" :readMode="readMode" :editMode="editMode" :activelyEditing="activelyEditing"  :isPermissionToEdit="isPermissionToEdit" :active.sync="active" @setActive="handleSetActive" @save="handleEmittedSave" @cancel="handleEmittedCancel" :content-position="contentPosition" />
  </div>
</template>

<script type="text/babel">
// import HoverableEditField from "../../assets/HoverableEditField.vue";
import TenantRentalDocumentLibrary from "./TenantRentalDocumentLibrary.vue";
import MoveInFees from "./MoveInFees.vue";
import TransferFees from "./TransferFees.vue";
import AddMerchandise from "./AddMerchandise.vue";
import MoveInCheckList from "./MoveInCheckList.vue";
import { mapGetters } from "vuex";
import { EventBus } from '../../../EventBus.js';

export default {
  name: "MoveInTransferConfigurations",
  components: {
    // HoverableEditField,
    TenantRentalDocumentLibrary,
    MoveInFees,
    TransferFees,
    AddMerchandise,
    MoveInCheckList,
  },
  props: ["template", "activelyEditing", "editMode", "readMode", "facilityLevel", 'isOPS', "contentPosition", "activeTab"],
  data() {
    return {
      leaseTypes: {
        monthToMonth: { text: "Month to Month", value: "Month to Month" },
        fixedLength: { text: "Fixed", value: "Fixed Length" },
      },
      deposit_options: {
        fixed: { text: "$", editModeText: "$",value: "fixed" },
        percent: { text: "%", editModeText: "%", value: "percent" },
        number: { text: "# of Months", editModeText: "Months", value: "number" },
      },
      lease_duration_types: {
        days: { text: "Day(s)", value: "Days" },
        months: { text: "Month(s)", value: "Months" },
        years: { text: "Years(s)", value: "Years" },
      },
      bill_on_options: {
        firstOfMonth: {
          text: "1st of the month (Calendar)",
          value: "1st of the Month",
        },
        Anniversary: { text: "Anniversary", value: "Anniversary" },
      },
      active: ''
    };
  },
  created(){
    EventBus.$on('lease-configuration-edit-mode-clear-active', this.clearActive);
  },
  destroyed() {
    EventBus.$off('lease-configuration-edit-mode-clear-active', this.clearActive);
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    invoiceDays() {
      let days = Array(31)
        .fill(0)
        .map((n, i) => i );
      return days;
    },
    isPermissionToEdit() {
      return this.facilityLevel ? false : this.hasPermission('view_edit_templates');
      // return false;
    },
    depositOption() {
      return (this.template && this.template.security_deposit_type) ? this.deposit_options[this.template.security_deposit_type].editModeText : '';
    }
  },
  methods: {
    async validateForm() {
      const status = await this.$validator.validateAll("form_2"); 
     
      // Added by BCT: documentLibrary removed from OPS, so suppress validation.    
      const documentLibraryValidationStatus = this.isOPS ? true : await this.$refs.TenantRentalDocumentLibrary.validateForm();
      const moveInChecklistValidationStatus = this.isOPS ? true : await this.$refs.MoveInChecklist.validateForm();
      return status && documentLibraryValidationStatus && moveInChecklistValidationStatus;
    },
    handleEmittedCancel(){
      this.$emit('cancelEdit');
    },
    handleEmittedSave(){
      this.$emit('saveData');
    },
    handleSetActive(label){
      this.active = label;
    },
    clearActive(){
      this.active = '';
    },
    handleInvoiceStatementFlag(){
      if(this.template.email_consolidated_statement && !this.template.email_statement) this.template.email_statement = true;
    }
  },
};
</script>

<style scoped>
.field-label {
  background-color: #f9fafb;
  color: #101318;
}
</style>
