<template>
  <div>
    <hb-form
      label="Add Product"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :empty="leaseTemplate.products.service && leaseTemplate.products.service[0] && !leaseTemplate.products.service[0].name && !readMode"
      :active="active"
      @editStart="handleEditStart('Add Product')"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      full
    >
      <template v-slot:display>
        <div class="hb-text-light mb-3" v-if="leaseTemplate.products.service && leaseTemplate.products.service[0] && leaseTemplate.products.service[0].name  && (!facilityLevel && !editMode)">
          The listed product(s) will be automatically applied to the lease.
        </div>
        <div
          v-for="(product, productIndex) in leaseTemplate.products.service"
          :key="productIndex"
          :class="{ 'mt-3' : productIndex > 0 }"
        >
          <div v-if="Object.keys(product).length !== 0">
            <div v-if="product.hasOwnProperty('Product')">
              {{ product.name }} ({{ formatPriceByType(product.price, product.Product.amount_type) }})
            </div>
            <div v-else>
              {{ product.name }} ({{ formatPriceByType(product.price, product.amount_type) }})
            </div>
            
          </div>
        </div>
      </template>
      <template v-slot:edit>
        <div class="hb-text-light mb-3">
          Select product(s) that will be automatically included in this lease. <span class="font-weight-medium">Note:</span> If the product you are looking for is not an option, you must create it on the product settings page.
        </div>
        <v-row
          v-for="(product, productIndex) in leaseTemplate.products.service"
          :key="productIndex"
          no-gutters
          class="pa-0 ma-0"
        >
          <v-col cols="10" no-gutters style="max-width:370px;">
            <HbAutocomplete
              v-model="leaseTemplate.products.service[productIndex]"
              :items="fees"
              item-text="name" 
              return-object
              placeholder="Select Product"
              :clearable="false"
            />
          </v-col>

          <v-col cols="2" no-gutters class="pl-2">
            <hb-tooltip>
              <template v-slot:item>
                <hb-icon pointer @click="removeMerchandiseFee(productIndex)">mdi-close</hb-icon>
              </template>
              <template v-slot:body>
                Remove
              </template>
            </hb-tooltip>
          </v-col>
        </v-row>

        <div v-if="addMerchandiseFeeOption" class="pt-2 pb-1">
          <hb-link @click="addMerchandiseFee">+ Add Product</hb-link>
        </div>
      </template>
    </hb-form>

    <!--
    <v-row class="mr-0 pl-3">
      <v-col class="field-label pa-0 pl-4" cols="3">
        <div class="font-weight-medium pt-2">
          Add Product
        </div>
      </v-col>
      <v-col class="font-weight-regular pa-0" cols="9">
        <hoverable-edit-field
          :activeEditing="activelyEditing == 'addMerchandise'"
          :hasPermissionToEdit="isPermissionToEdit"
          name="addMerchandise"
        >
          <template slot="editingMode">
            <div class="field-description">
              Select product that will be automatically included in this
              lease.
              <strong>Note:</strong> If the product you are looking for is
              not an option, you must create it on the product settings
              page.

              <div class="pt-0">
                <div
                  class="pl-0 pt-3"
                  v-for="(product, productIndex) in leaseTemplate.products.service"
                  :key="productIndex"
                >
                  <v-row class="pa-0 ma-0">
                    <v-col cols="8" class="ma-0 pa-0">
                      <div>
                        <v-select
                          :items="fees"
                          label="Select Product"
                          hide-details
                          attach
                          item-text="name" 
                          class="pa-0 mt-0"
                          single-line
                          return-object
                          v-model="leaseTemplate.products.service[productIndex]"
                        ></v-select>
                      </div>
                    </v-col>
                    <v-col cols="3" class="ma-0 pa-0">
                      <div>
                        <v-text-field
                          single-line
                          label="Amount"
                          hide-details
                          class="ma-0 pa-0 ml-2"
                          v-model="leaseTemplate.products.service[productIndex].qty"
                        >
                        </v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="3" class="ma-0 pa-0">
                      <div>
                        <v-text-field
                          single-line
                          label="Price"
                          hide-details
                          class="ma-0 pa-0 ml-2"
                          v-model="leaseTemplate.products.service[productIndex].price"
                        >
                        </v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="2" v-if="productIndex != 0" class="pa-0 ma-0 pl-2 mt-2">
                      <hb-icon @click="removeMerchandiseFee(productIndex)">mdi-close</hb-icon>
                    </v-col>
                  </v-row>
                </div>

                <div class="pt-3" v-if="addMerchandiseFeeOption">
                  <a class="hb-link" @click="addMerchandiseFee">+ Add Product</a>
                </div>
              </div>
            </div>
          </template>
          <template slot="readMode" v-if="editMode">
            <div>
              <div class="pb-3" v-if="!facilityLevel">
                The listed fees will be automatically applied to the lease.
              </div>
              <div
                class="pl-0"
                v-for="(product, productIndex) in leaseTemplate.products.service"
                :key="productIndex"
              >
                <div v-if="product.name">
                  <div v-if="product.Product.product_amount === 'percentage' ">
                    {{ product.name }} (%{{ product.price }})
                  </div>
                  <div v-else>
                    {{ product.name }} (${{ product.price }})
                  </div>
                  
                </div>
              </div>
              <div class="hb-text-light" v-if="leaseTemplate.products.service && !leaseTemplate.products.service[0].name">
                No Product Added 
              </div>              
            </div>
          </template>
        </hoverable-edit-field>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    -->

  </div>
</template>

<script type="text/babel">
// import HoverableEditField from "../../assets/HoverableEditField.vue";
import api from "../../../assets/api.js";
import { mapGetters } from "vuex";
import { formatPriceByType } from "../../../utils/common";

export default {
  name: "AddMerchandise",
  // components: { HoverableEditField },
  data() {
    return {
      fees: [],
      addMerchandiseFeeOption: true
    };
  },
  async created() {
    await this.fetchFees();
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    readMode: {
      type: Boolean,
      default: false,
    },
    active: {
      type: String,
      default: "",
    },
    activelyEditing: {
      type: String,
      default: "",
    },
    leaseTemplate: {
      type: Object,
      required: true
    },
    facilityLevel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    isPermissionToEdit() {
      return this.facilityLevel ? false : this.hasPermission('view_edit_templates');
    }
  },
  methods: {
    async fetchFees() {
      let res = await api.get(this, api.PRODUCTS + "list?type=product&all=true&search=");
      this.fees = res.products;
    },
    addMerchandiseFee() {
      this.leaseTemplate.products.service.push({ });
    },
    removeMerchandiseFee(index) {
      this.leaseTemplate.products.service.splice(index, 1);
    },
    async validateForm() {
      let status = await this.$validator.validateAll("form");
      return status;
    },
    handleEmittedCancel(){
     this.$emit('cancel');
      setTimeout(() => {
        if(this.active === 'Add Product'){
          this.$emit('setActive', '');
        }
      }, 500);
    },
    handleEmittedSave(){
      this.$emit('save');
    },
    handleEditStart(label){
      this.$emit('setActive', label);
    },
    formatPriceByType
  }
};
</script>

<style scoped>
.field-label {
  background-color: #f9fafb;
  color: #101318;
}
</style>