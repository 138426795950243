<template>
  <div>
    <hb-form
      label="Move-In Fees"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :empty="leaseTemplate.products.movein && leaseTemplate.products.movein[0] && !leaseTemplate.products.movein[0].name && !readMode"
      :active="active"
      @editStart="handleEditStart('Move-In Fees')"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      full
    >
      <template v-slot:display>
        <div class="hb-text-light mb-3" v-if="leaseTemplate.products.movein && leaseTemplate.products.movein[0] && leaseTemplate.products.movein[0].name && (!facilityLevel && !editMode)">
          The listed fee(s) will be automatically applied to the lease.
        </div>
        <div
          v-for="(product, productIndex) in leaseTemplate.products.movein"
          :key="productIndex"
          :class="{ 'mt-3' : productIndex > 0 }"
        >
          <div v-if="product.name">
              <div v-if="isPercentage(product.Product)">
                {{ product.name }} ({{ product.price }}%)
              </div>
            <div v-else>
              {{ product.name }} (${{ product.price }})
            </div>
          </div>
        </div>
      </template>
      <template v-slot:edit>
        <div class="hb-text-light mb-3">
          Select the move-in fees(s) that will be automatically applied as part of this lease. <span class="font-weight-medium">Note:</span> If a fee is not listed as an option, you must first create it on the fees setting page.
        </div>
        <v-row
          v-for="(product, productIndex) in leaseTemplate.products.movein"
          :key="productIndex"
          no-gutters
          class="pa-0 ma-0"
        >
          <v-col cols="10" no-gutters style="max-width:370px;">
            <HbAutocomplete
              v-model="leaseTemplate.products.movein[productIndex]"
              :items="fees"
              item-text="name" 
              return-object
              placeholder="Select Fee"
              :clearable="false"
            />
          </v-col>

          <v-col cols="2" no-gutters class="pl-2">
            <hb-tooltip>
              <template v-slot:item>
                <hb-icon pointer @click="removeMoveInFee(productIndex)">mdi-close</hb-icon>
              </template>
              <template v-slot:body>
                Remove
              </template>
            </hb-tooltip>
          </v-col>
        </v-row>

        <div v-if="addMoveInFeeOption" class="pt-2 pb-1">
          <hb-link @click="addMoveInFee">+ Add Fee</hb-link>
        </div>
      </template>
    </hb-form>

    <!--
    <v-col class="field-label pa-0 pl-4" cols="3">
      <div class="font-weight-medium pt-2">
        Move-In Fees
      </div>
    </v-col>
    <v-col class="font-weight-regular pa-0" cols="9">
      <hoverable-edit-field
        :activeEditing="activelyEditing == 'moveInFees'"
        :hasPermissionToEdit="isPermissionToEdit"
        name="moveInFees"
      >
        <template slot="editingMode">
          <v-row class="mr-0">
            <v-col class="font-weight-regular pb-0 pt-0" cols="12">
              <div>
                <span class="field-description">
                  Select the move-in fees(s) that will be automatically applied as
                  part of this lease.
                  <strong>Note:</strong> If a fee is not listed as an option, you
                  must first create it on the fees setting page.
                </span>

                <div class="pt-0">
                  <div
                    class="pl-0 pt-3"
                    v-for="(product, productIndex) in leaseTemplate.products.movein"
                    :key="productIndex"
                  >
                    <v-row class="pa-0 ma-0">
                      <v-col cols="8" class="ma-0 pa-0">
                        <div>
                          <v-select
                            :items="fees"
                            label="Select Fee"
                            hide-details
                            attach
                            item-text="name" 
                            class="pa-0 mt-0"
                            single-line
                            return-object
                            v-model="leaseTemplate.products.movein[productIndex]"
                          ></v-select>
                        </div>
                      </v-col>
                      <v-col v-if="productIndex != 0" cols="2" class="pa-0 ma-0 pl-2 mt-2">
                        <hb-icon @click="removeMoveInFee(productIndex)">mdi-close</hb-icon>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="pt-3" v-if="addMoveInFeeOption">
                    <a class="hb-link" @click="addMoveInFee">+ Add Fee</a>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </template>
        <template slot="readMode" v-if="editMode">
          <div>
            <div class="pb-3" v-if="!facilityLevel">The listed fees will be automatically applied to the lease.</div>
            <div
              class="pl-0"
              v-for="(product, productIndex) in leaseTemplate.products.movein"
              :key="productIndex"
            >
              <div v-if="product.name">
                  <div v-if="isPercentage(product.Product)">
                    {{ product.name }} ({{ product.price }}%)
                  </div>
                <div v-else>
                  {{ product.name }} (${{ product.price }})
                </div>
              </div>
              
            </div>
            <div class="hb-text-light" v-if="leaseTemplate.products.movein && !leaseTemplate.products.movein[0].name">
              No Fees Added 
            </div>
          </div>
        </template>
      </hoverable-edit-field>
    </v-col>
    <v-col cols="12" class="pa-0">
      <v-divider></v-divider>
    </v-col>
    -->

  </div>
</template>

<script type="text/babel">
// import HoverableEditField from "../../assets/HoverableEditField.vue";
import api from "../../../assets/api.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fees: [],
      addMoveInFeeOption: true
    }
  },
  // components: { HoverableEditField },
  name: "MoveInFees",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    readMode: {
      type: Boolean,
      default: false,
    },
    active: {
      type: String,
      default: "",
    },
    activelyEditing: {
      type: String,
      default: "",
    },
    leaseTemplate: {
      type: Object,
      required: true
    },
    facilityLevel: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async fetchFees() {
      try {
        let res = await api.get(this, api.PRODUCTS + "?category_type=moveinonly&&search=late");
        this.fees = res.products;
      } catch(err) {
        // display notification
      }
    },
    addMoveInFee() {
      this.leaseTemplate.products.movein.push({ });
    },
    removeMoveInFee(index) {
      this.leaseTemplate.products.movein.splice(index, 1);
    },
    isPercentage(product){
      if(!product || product.amount_type === undefined) return false
      return product.amount_type === 'percentage';
    },
    async validateForm() {
      let status = await this.$validator.validateAll("form");
      return status;
    },
    handleEmittedCancel(){
     this.$emit('cancel');
      setTimeout(() => {
        if(this.active === 'Move-In Fees'){
          this.$emit('setActive', '');
        }
      }, 500);
    },
    handleEmittedSave(){
      this.$emit('save');
    },
    handleEditStart(label){
      this.$emit('setActive', label);
    }
  },
  mounted() {
    this.$validator.extend('moveinfee', {
      getMessage: field => field + ' is a duplicate move-in fee',
      validate: value => { 
        console.log(this.leaseTemplate.products);
        let count = 0;
        for (let i = 0; i < this.leaseTemplate.products.movein.length; i++) {
          if (this.leaseTemplate.products.movein[i].product_id != null && this.leaseTemplate.products.movein[i].product_id == value.product_id) {
            console.log(this.leaseTemplate.products.movein[i].product_id);
            count++;
            if(count >= 2){
              return false;
            }
          } 
          else if(this.leaseTemplate.products.movein[i].id != null && this.leaseTemplate.products.movein[i].id == value.id){
            console.log(this.leaseTemplate.products.movein[i].id);
            count++;
            if(count >= 2){
              return false;
            }
          }
        }
        return true;
      } 
    });
  },
  async created() {
    await this.fetchFees();
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    isPermissionToEdit() {
      return this.facilityLevel ? false : this.hasPermission('view_edit_templates');
    }
  }
};
</script>

<style scoped>
.field-label {
  background-color: #f9fafb;
  color: #101318;
}
</style>