<template>
  <div>
    <hb-form
      label="Transfer Fees"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :empty="leaseTemplate.products.transfer && leaseTemplate.products.transfer[0] && !leaseTemplate.products.transfer[0].name && !readMode"
      :active="active"
      @editStart="handleEditStart('Transfer Fees')"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      full
    >
      <template v-slot:display>
        <div class="hb-text-light mb-3" v-if="leaseTemplate.products.transfer && leaseTemplate.products.transfer[0] && leaseTemplate.products.transfer[0].name && (!facilityLevel && !editMode)">
          The listed fee(s) will be automatically applied to the lease.
        </div>
        <div
          v-for="(product, productIndex) in leaseTemplate.products.transfer"
          :key="productIndex"
          :class="{ 'mt-3' : productIndex > 0 }"
        >
          <div v-if="product.name">
            <div v-if="isPercentage(product)">
              {{ product.name }} ({{ product.price }}%)
            </div>
            <div v-else>
              {{ product.name }} (${{ product.price }})
            </div>
          </div>
        </div>
      </template>
      <template v-slot:edit>
        <div class="hb-text-light mb-3">
          Select transfer fee(s) that will be applied, transfer fees will only be applied when you’re transferring a tenant to a new space. <span class="font-weight-medium">Note:</span> If a fee is not listed as an option, you must create it on the fees setting page.
        </div>
        <v-row
          v-for="(product, productIndex) in leaseTemplate.products.transfer"
          :key="productIndex"
          no-gutters
          class="pa-0 ma-0"
        >
          <v-col cols="10" no-gutters style="max-width:370px;">
            <HbAutocomplete
              v-model="leaseTemplate.products.transfer[productIndex]"
              :items="fees"
              item-text="name" 
              return-object
              placeholder="Select Fee"
              :clearable="false"
            />
          </v-col>

          <v-col cols="2" no-gutters class="pl-2">
            <hb-tooltip>
              <template v-slot:item>
                <hb-icon pointer @click="removeTransferFee(productIndex)">mdi-close</hb-icon>
              </template>
              <template v-slot:body>
                Remove
              </template>
            </hb-tooltip>
          </v-col>
        </v-row>

        <div v-if="showAddTransferFeeOption" class="pt-2 pb-1">
          <hb-link @click="addTransferFee">+ Add Fee</hb-link>
        </div>
      </template>
    </hb-form>

    <!--
    <v-row class="mr-0 pl-3">
      <v-col class="field-label pa-0 pl-4" cols="3">
        <div class="font-weight-medium pt-2">          
          Transfer Fees
        </div>
      </v-col>
      <v-col class="font-weight-regular pa-0" cols="9">
        <hoverable-edit-field
          :activeEditing="activelyEditing == 'transferFees'"
          :hasPermissionToEdit="isPermissionToEdit"
          name="transferFees"
        >
          <template slot="editingMode">
            <div>
              <span class="field-description">
                Select transfer fee(s) that will be applied, transfer fees will
                only be applied when you’re transferring a tenant to a new space.
                <strong>Note:</strong> If a fee is not listed as an option, you
                must create it on the fees setting page.
              </span>

              <div class="pt-0">
                <div
                  class="pl-0 pt-3"
                  v-for="(product, productIndex) in leaseTemplate.products.transfer"
                  :key="productIndex"
                >
                  <v-row class="pa-0 ma-0">
                    <v-col cols="8" class="ma-0 pa-0">
                      <div>
                        <v-select
                          :items="fees"
                          label="Select Fee"
                          hide-details
                          attach
                          class="pa-0 mt-0"
                          single-line
                          return-object
                          item-text="name" 
                          v-model="leaseTemplate.products.transfer[productIndex]"
                        ></v-select>
                      </div>
                    </v-col>
                    <v-col v-if="productIndex != 0" cols="2" class="pa-0 ma-0 pl-2 mt-2">
                      <hb-icon  @click="removeTransferFee(productIndex)">mdi-close</hb-icon>
                    </v-col>
                  </v-row>
                </div>
                

                <div class="pt-3" v-if="showAddTransferFeeOption">
                    <a class="hb-link" @click="addTransferFee">+ Add Fee</a>
                  </div>
              </div>
            </div>
          </template>
          <template slot="readMode" v-if="editMode">
            <div>
              <div class="pb-3" v-if="!facilityLevel">
                The listed fees will be automatically applied to the lease.
              </div>
              <div
                class="pl-0"
                v-for="(product, productIndex) in leaseTemplate.products.transfer"
                :key="productIndex"
              >
                <div v-if="product.name">
                  <div v-if="isPercentage(product)">
                    {{ product.name }} ({{ product.price }}%)
                  </div>
                  <div v-else>
                    {{ product.name }} (${{ product.price }})
                  </div>
                </div>


                </div>
              <div class="hb-text-light" v-if="leaseTemplate.products.transfer && !leaseTemplate.products.transfer[0].name">
                No Fees Added 
              </div>
            </div>
          </template>
        </hoverable-edit-field>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    -->

  </div>
</template>

<script type="text/babel">
// import HoverableEditField from "../../assets/HoverableEditField.vue";
import api from "../../../assets/api.js";
import { mapGetters } from "vuex";

export default {
  // components: { HoverableEditField },
  name: "TransferFees",
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    readMode: {
      type: Boolean,
      default: false,
    },
    active: {
      type: String,
      default: "",
    },
    activelyEditing: {
      type: String,
      default: ""
    },
    leaseTemplate: {
      type: Object,
      required: true
    },
    facilityLevel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fees: [],
      showAddTransferFeeOption: true
    };
  },
  methods: {
    async fetchFees() {
      try {
        let res = await api.get(this, api.PRODUCTS + "?category_type=transferonly&&search=late");
        this.fees = res.products;
      } catch(err) {
        // display notification
      }
    },
    addTransferFee() {
      this.leaseTemplate.products.transfer.push({ });
    },
    removeTransferFee(index) {
      this.leaseTemplate.products.transfer.splice(index, 1);
    },
    isPercentage(product){
      console.log({product});
      if(!product || product === undefined || product.Product === undefined) return false
      return product.Product.amount_type === 'percentage';
    },
    async validateForm() {
      let status = await this.$validator.validateAll("form");
      return status;
    },
    handleEmittedCancel(){
     this.$emit('cancel');
      setTimeout(() => {
        if(this.active === 'Transfer Fees'){
          this.$emit('setActive', '');
        }
      }, 500);
    },
    handleEmittedSave(){
      this.$emit('save');
    },
    handleEditStart(label){
      this.$emit('setActive', label);
    }
  },
  mounted() {
    this.$validator.extend('transferfee', {
      getMessage: field => field + ' is a duplicate transfer fee',
      validate: value => { 
        let count = 0;
        for (let i = 0; i < this.leaseTemplate.products.transfer.length; i++) {
          if (this.leaseTemplate.products.transfer[i].product_id != null && this.leaseTemplate.products.transfer[i].product_id == value.product_id) {
            count++;
            if(count >= 2){
              return false;
            }
          } 
          else if(this.leaseTemplate.products.transfer[i].id != null && this.leaseTemplate.products.transfer[i].id == value.id){
            count++;
            if(count >= 2){
              return false;
            }
          }
        }
        return true;
      }
    });
  },
  async created() {
    await this.fetchFees();
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    isPermissionToEdit() {
      return this.facilityLevel ? false : this.hasPermission('view_edit_templates');
    }
  }
};
</script>

<style scoped>
.field-label {
  background-color: #f9fafb;
  color: #101318;
}
</style>