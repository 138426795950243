<template>
  <div>
    <hb-form
      label="Tenant Rental Document Library"
      :required="!readMode"
      :editable="(editMode && isPermissionToEdit && !leaseDocumentsLoading) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :empty="!documentFound && !readMode"
      :active="active"
      @editStart="handleEditStart('Tenant Rental Document Library')"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      :auto-layout="false"
      full
    >
      <template v-slot:display>
        <div v-if="leaseDocumentsLoading" class="d-flex align-center">
          Loading Documents..
           <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div class="hb-text-light pb-11" v-else-if="!documentFound">
          No Documents Added
        </div>
        <div v-else class="mt-n2">
          <div
            v-for="(checklistItem, checklistIndex) in leaseTemplate.checklist"
            :key="checklistIndex"
          >
            <div v-for="(doc, docIndex) in checklistItem" :key="docIndex" class="mt-3">
              <v-container
                 v-if="doc.document_id"
                no-gutters
                class="hb-aviary-bordered-box pa-3 tenant-rental-document-library-v-container-box"
              >
                <hb-checkbox v-model="doc.document_id" readonly>
                  <template v-slot:label>
                    <v-row no-gutters>
                      <v-col cols="12" no-gutters>
                        {{ getDocumentNameByTag(doc) === 'Autopay Enrollment Document' ? 'AutoPay Enrollment Document' : getDocumentNameByTag(doc) }}
                      </v-col>
                      <v-col
                        v-if="findDocument(doc.document_id, (doc.tag || doc.document_tag)).name"
                        cols="12"
                        no-gutters
                        class="mt-1"
                      >
                        <!--
                        <hb-link color="secondary" @click="">
                          {{ findDocument(doc.document_id, (doc.tag || doc.document_tag)).name || '' }}
                        </hb-link>
                        -->
                        <span class="font-weight-medium">
                          {{ findDocument(doc.document_id, (doc.tag || doc.document_tag)).name || '' }}
                        </span>
                      </v-col>
                    </v-row>
                  </template>
                </hb-checkbox>
              </v-container>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:edit>
        <div class="hb-text-light">
          Select the documents you’ll require your tenants to complete at move-in, you must select a lease document. Additionally, select all other documents that are required based on the tenant’s choice. (i.e. Insurance Enrollment Form, AutoPay Enrollment Form, etc...)
        </div>
        <v-container no-gutters class="hb-aviary-bordered-box pa-3 mt-4 tenant-rental-document-library-v-container-box">
          <hb-checkbox :value="true" readonly>
            <template v-slot:label>
              <v-row no-gutters>
                <v-col cols="12" no-gutters>
                  Lease Agreement*
                  <hb-tooltip class="d-inline-flex">
                    <template slot="body">
                      You must include a lease agreement to <br />
                      proceed with the lease configuration <br />
                      process. The lease document you should <br />
                      use depends on the state where you <br />
                      operate.
                    </template>
                  </hb-tooltip>
                </v-col>
                <v-col cols="12" no-gutters class="pt-1 tenant-rental-document-library-edit-margin-top-fix-1px">
                  <hb-autocomplete
                    v-if="leaseTemplate.checklist['lease'].length && leaseTemplate.checklist['lease'][0].name"
                    v-model="leaseTemplate.checklist['lease'][0].document_id"
                    v-validate="`${ getLeaseDocumentsByKey('lease') ? 'required' : '' }`"
                    :items="getLeaseDocumentsByKey('lease')"
                    item-text="name"
                    item-value="id"
                    :clearable="false"
                    id="lease_document"
                    name="lease_document"                  
                    placeholder="Select Document"
                    data-vv-as="Lease Agreement Document"
                    data-vv-scope="form"
                    :error="errors.has('form.lease_document')"
                  >
                    <template v-slot:append-item>
                      <div id="lease" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          v-if="leaseTemplatesLoading"
                        ></v-progress-circular>
                      </div>
                    </template>
                  </hb-autocomplete>
                </v-col>
              </v-row>
            </template>
          </hb-checkbox>
        </v-container>

        <v-container no-gutters class="hb-aviary-bordered-box mt-3 pa-3 tenant-rental-document-library-v-container-box">
          <span v-if="leaseTemplate.auto_pay">
            <hb-checkbox v-model="leaseTemplate.auto_pay" readonly label="AutoPay Enrollment*">
              <template v-slot:tooltipBody>
                Have your tenants sign an AutoPay <br />
                enrollment document to authorize you to <br />
                run their payment method on an <br />
                automated schedule.
              </template>
            </hb-checkbox>
          </span>
          <v-checkbox
            v-else
            :input-value="isDocumentSelected('autopay')"
            class="mt-0"
            flat
            hide-details
            single-line
            solo
            @change="updateRequiredDocuments($event, 'autopay', 'Autopay Enrollment Document')"
          >
            <span slot="label" class="hb-text-night">
              AutoPay Enrollment
              <hb-tooltip class="d-inline-flex">
                <template slot="body">
                  Have your tenants sign an AutoPay <br />
                  enrollment document to authorize you to <br />
                  run their payment method on an <br />
                  automated schedule.
                </template>
              </hb-tooltip>
            </span>
          </v-checkbox>
          <div class="pl-8 tenant-rental-document-library-edit-margin-top-fix-3px">
            <hb-autocomplete
              v-if="
                leaseTemplate.checklist['autopay'].length &&
                leaseTemplate.checklist['autopay'][0].name
              "
              v-model="leaseTemplate.checklist['autopay'][0].document_id"
              v-validate="isDocumentSelected('autopay') ? 'required' : ''"
              :items="getLeaseDocumentsByKey('autopay')"
              item-text="name"
              item-value="id"
              :clearable="false"
              id="auto_pay_document"
              name="auto_pay_document"      
              placeholder="Select Document"
              data-vv-as="AutoPay Document"
              data-vv-scope="form"
              :error="errors.has('form.auto_pay_document')"
            >
              <template v-slot:append-item>
                <div id="autopay" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="leaseTemplatesLoading"
                  ></v-progress-circular>
                </div>
              </template>
            </hb-autocomplete>
          </div>
        </v-container>

        <v-container no-gutters class="hb-aviary-bordered-box mt-3 pa-3 tenant-rental-document-library-v-container-box">
          <v-checkbox
            :input-value="isDocumentSelected('vehicle')"
            class="mt-0"
            flat
            hide-details
            single-line
            solo
            @change="updateRequiredDocuments($event, 'vehicle', 'Vehicle Addendum Document')"
          >
            <span slot="label" class="hb-text-night">
              Vehicle Addendum
              <hb-tooltip class="d-inline-flex">
                <template slot="body">
                  A vehicle addendum is used when a <br />
                  tenant informs you that they plan to store <br />
                  a vehicle at your property.
                </template>
              </hb-tooltip>
            </span>
          </v-checkbox>
          <div class="pl-8 tenant-rental-document-library-edit-margin-top-fix-3px">
            <hb-autocomplete
              v-if="
                leaseTemplate.checklist['vehicle'].length &&
                leaseTemplate.checklist['vehicle'][0].name
              "
              v-model="leaseTemplate.checklist['vehicle'][0].document_id"
              v-validate="isDocumentSelected('vehicle') ? 'required' : ''"
              :items="getLeaseDocumentsByKey('vehicle')"
              item-text="name"
              item-value="id"
              :clearable="false"
              placeholder="Select Document"
              data-vv-scope="form"
              data-vv-name="vehicle_addendum_document"
              data-vv-as="Vehicle Addendum Document"
              :error="errors.has('form.vehicle_addendum_document')"
            >
              <template v-slot:append-item>
                <div id="vehicle" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="leaseTemplatesLoading"
                  ></v-progress-circular>
                </div>
              </template>
            </hb-autocomplete>
          </div>
        </v-container>

        <v-container no-gutters class="hb-aviary-bordered-box mt-3 pa-3 tenant-rental-document-library-v-container-box">
          <v-checkbox
            :input-value="isDocumentSelected('enroll-coverage')"
            class="mt-0"
            flat
            hide-details
            single-line
            solo
            @change="
              updateRequiredDocuments($event, 'enroll-coverage', 'Insurance / Protection Enrollment Document')
            "
          >
            <span slot="label" class="hb-text-night">
              Insurance / Protection Enrollment
              <hb-tooltip class="d-inline-flex">
                <template slot="body">
                  Use an insurance/protection enrollment <br />
                  document to inform tenants that you <br />
                  require coverage to rent a space at your <br />
                  property.
                </template>
              </hb-tooltip>
            </span>
          </v-checkbox>
          <div class="pl-8 tenant-rental-document-library-edit-margin-top-fix-3px">
            <hb-autocomplete
              v-if="
                leaseTemplate.checklist['enroll-coverage'].length &&
                leaseTemplate.checklist['enroll-coverage'][0].name
              "
              v-model="leaseTemplate.checklist['enroll-coverage'][0].document_id"
              v-validate="isDocumentSelected('enroll-coverage') ? 'required' : ''"
              :items="getLeaseDocumentsByKey('enroll-coverage')"
              item-text="name"
              item-value="id"
              :clearable="false"
              placeholder="Select Document"
              data-vv-scope="form"
              data-vv-name="insurance_protection_document"
              data-vv-as="Insurance / Protection Document"
              :error="errors.has('form.insurance_protection_document')"
            >
              <template v-slot:append-item>
                <div id="enroll-coverage" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="leaseTemplatesLoading"
                  ></v-progress-circular>
                </div>
              </template>
            </hb-autocomplete>
          </div>
        </v-container>

        <v-container no-gutters class="hb-aviary-bordered-box mt-3 pa-3 tenant-rental-document-library-v-container-box">
          <v-checkbox
            :input-value="isDocumentSelected('deny-coverage')"
            class="mt-0"
            flat
            hide-details
            single-line
            solo
            @change="
              updateRequiredDocuments($event, 'deny-coverage', 'Deny Insurance/Protection Form')
            "
          >
            <span slot="label" class="hb-text-night">
              Deny Insurance / Protection Form
              <hb-tooltip class="d-inline-flex">
                <template slot="body">
                  Tenants can deny the <br />
                  insurance/protection you provide at your <br />
                  property using a deny insurance <br />
                  document.
                </template>
              </hb-tooltip>
            </span>
          </v-checkbox>
          <div class="pl-8 tenant-rental-document-library-edit-margin-top-fix-3px">
            <hb-autocomplete
              v-if="
                leaseTemplate.checklist['deny-coverage'].length &&
                leaseTemplate.checklist['deny-coverage'][0].name
              "
              v-model="leaseTemplate.checklist['deny-coverage'][0].document_id"
              v-validate="isDocumentSelected('deny-coverage') ? 'required' : ''"
              :items="getLeaseDocumentsByKey('deny-coverage')"
              item-text="name"
              item-value="id"
              :clearable="false"
              placeholder="Select Document"
              data-vv-scope="form"
              data-vv-name="deny_insurance_protection_document"
              data-vv-as="Deny Insurance / Protection Document"
              :error="errors.has('form.deny_insurance_protection_document')"
            >
              <template v-slot:append-item>
                <div id="deny-coverage" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="leaseTemplatesLoading"
                  ></v-progress-circular>
                </div>
              </template>
            </hb-autocomplete>
          </div>
        </v-container>

        <v-container no-gutters class="hb-aviary-bordered-box mt-3 pa-3 tenant-rental-document-library-v-container-box">
          <v-checkbox
            :input-value="isDocumentSelected('military')"
            class="mt-0"
            flat
            hide-details
            single-line
            solo
            @change="updateRequiredDocuments($event, 'military', 'Military Waiver Document')"
          >
            <span slot="label" class="hb-text-night">
              Military Waiver
              <hb-tooltip class="d-inline-flex">
                <template slot="body">
                  A military waiver is used when a tenant or <br />
                  a spouse is a member of the U.S. military <br />
                  and they are signing an SCRA.
                </template>
              </hb-tooltip>
            </span>
          </v-checkbox>
          <div class="pl-8 tenant-rental-document-library-edit-margin-top-fix-3px">
            <hb-autocomplete
              v-if="
                leaseTemplate.checklist['military'].length &&
                leaseTemplate.checklist['military'][0].name
              "
              v-model="leaseTemplate.checklist['military'][0].document_id"
              v-validate="isDocumentSelected('military') ? 'required' : ''"
              :items="getLeaseDocumentsByKey('military')"
              item-text="name"
              item-value="id"
              :clearable="false"
              placeholder="Select Document"
              data-vv-scope="form"
              data-vv-name="military_document"
              data-vv-as="Military Document"
              :error="errors.has('form.military_document')"
            >
              <template v-slot:append-item>
                <div id="military" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="leaseTemplatesLoading"
                  ></v-progress-circular>
                </div>
              </template>
            </hb-autocomplete>
          </div>
        </v-container>

        <div class="pt-6 pb-1 hb-text-light">
          You may also add additional documents to include them to the list of materials your tenants must sign at move-in.
        </div>

        <v-container
          v-for="(doc, docIndex) in noTagDocuments"
          :key="docIndex"
          no-gutters
          class="hb-aviary-bordered-box mt-3 pa-3 tenant-rental-document-library-v-container-box"
        >
          <hb-checkbox :value="true" readonly>
            <template v-slot:label>
              <v-row no-gutters>
                <v-col cols="10" no-gutters>
                  <hb-text-field
                    v-model="doc.name"
                    v-validate="'required|max:45'"
                    placeholder="Enter Custom Name"
                    data-vv-scope="form"
                    :data-vv-name="'custom_name_' + docIndex"
                    :data-vv-as="'Custom Name ' + (docIndex + 1)"
                    :error="errors.has('form.custom_name_' + docIndex)"
                    style="position:relative;top:-6px;"
                  >
                  </hb-text-field>
                </v-col>
                <v-col cols="2" no-gutters class="pl-7">
                  <hb-tooltip>
                    <template v-slot:item>
                      <hb-icon pointer @click="removeCustomDocument(docIndex)">mdi-close</hb-icon>
                    </template>
                    <template v-slot:body>
                      Remove
                    </template>
                  </hb-tooltip>
                </v-col>
                <v-col cols="12" no-gutters class="pt-1 tenant-rental-document-library-edit-margin-top-fix-1px">
                  <hb-autocomplete
                    v-model="doc.document_id"
                    v-validate="'required'"
                    :items="getLeaseDocumentsByKey('other')"
                    item-text="name"
                    item-value="id"
                    :clearable="false"
                    :filter="customFilter"
                    placeholder="Select Document"
                    data-vv-scope="form"
                    :data-vv-name="'custom_document_' + docIndex"
                    :data-vv-as="'Custom Document ' + (docIndex + 1)"
                    :error="errors.has('form.custom_document_' + docIndex)"
                  >
                    <template v-slot:append-item>
                      <div id="other" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          v-if="leaseTemplatesLoading"
                        ></v-progress-circular>
                      </div>
                    </template>
                  </hb-autocomplete>
                </v-col>
              </v-row>
            </template>
          </hb-checkbox>
        </v-container>

        <div class="pt-3 pb-1" v-if="showAddDocumentOption">
          <hb-link @click="addCustomDocument">+ Add Document</hb-link>
        </div>

      </template>
    </hb-form>
        
        <!--
        <div>

          <div class="pt-3">
            <v-row class="pa-0 ma-0">
              <v-col cols="8" class="ma-0 pa-0">
                <v-card class="pa-4" elevation="1">
                  <span>
                    <hb-icon color="#101318">mdi-check</hb-icon> Lease Agreement*
                    <hb-tooltip class="d-inline-flex">
                      <template slot="body">
                        You must include a lease agreement to <br />
                        proceed with the lease configuration <br />
                        process. The lease document you should <br />
                        use depends on the state where you <br />
                        operate.
                      </template>
                    </hb-tooltip>
                  </span>
                    <div>
                      <v-select
                        v-if="leaseTemplate.checklist['lease'].length && leaseTemplate.checklist['lease'][0].name"
                        :items="getLeaseDocumentsByKey('lease')"
                        :error-messages="errors.first('form.lease_document')"
                        id="lease_document"
                        name="lease_document"                  
                        label="Select Document"
                        attach
                        hide-details
                        class="pa-0 mt-0"
                        single-line
                        item-text="name"
                        item-value="id"
                        data-vv-as="Lease Configuration Library"
                        data-vv-scope="form"
                        v-validate="`${ getLeaseDocumentsByKey('lease') ? 'required' : '' }`"
                        required
                        v-model="leaseTemplate.checklist['lease'][0].document_id"
                      >
                      <template v-slot:append-item>
                        <div id="lease" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            v-if="leaseTemplatesLoading"
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-select>
                    </div>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="pa-0 ma-0 mt-3">
              <v-col cols="8" class="ma-0 pa-0">
                <v-card elevation="1" class="pa-4">
                  <span v-if="leaseTemplate.auto_pay">
                    <hb-icon color="#101318">mdi-check</hb-icon> AutoPay Enrollment*
                  </span>
                  <v-checkbox
                    v-else
                    :input-value="isDocumentSelected('autopay')"
                    class="ml-n1 my-0 mb-2"
                    flat
                    hide-details
                    single-line
                    solo
                    @change="updateRequiredDocuments($event, 'autopay', 'Autopay Enrollment Document')"
                  >
                    <span slot="label" class="hb-text-night">
                      AutoPay Enrollment
                      <hb-tooltip class="d-inline-flex">
                        <template slot="body">
                          Have your tenants sign an autopay <br />
                          enrollment document to authorize you to <br />
                          run their payment method on an <br />
                          automated schedule.
                        </template>
                      </hb-tooltip>
                    </span>
                  </v-checkbox>
                  <div>
                    <v-select
                      v-if="
                        leaseTemplate.checklist['autopay'].length &&
                        leaseTemplate.checklist['autopay'][0].name
                      "
                      :items="getLeaseDocumentsByKey('autopay')"
                      :error-messages="errors.first('form.auto_pay_document')"
                      attach
                      id="auto_pay_document"
                      name="auto_pay_document"      
                      label="Select Document"
                      hide-details
                      item-text="name"
                      class="pa-0 mt-0"
                      single-line
                      item-value="id"
                      data-vv-as="AutoPay Document"
                      data-vv-scope="form"
                      v-validate="`${ leaseTemplate.auto_pay && getLeaseDocumentsByKey('autopay') ? 'required' : '' }`"
                      v-model="leaseTemplate.checklist['autopay'][0].document_id"
                    >
                      <template v-slot:append-item>
                        <div id="autopay" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            v-if="leaseTemplatesLoading"
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-select>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0 mt-3">
              <v-col cols="8" class="ma-0 pa-0">
                <v-card elevation="1" class="pa-4">
                  <v-checkbox
                    :input-value="isDocumentSelected('vehicle')"
                    class="ml-n1 my-0 mb-2"
                    flat
                    hide-details
                    single-line
                    solo
                    @change="updateRequiredDocuments($event, 'vehicle', 'Vehicle Addendum Document')"
                  >
                    <span slot="label" class="hb-text-night">
                      Vehicle Addendum
                      <hb-tooltip class="d-inline-flex">
                        <template slot="body">
                          A vehicle addendum is used when a <br />
                          tenant informs you that they plan to store <br />
                          a vehicle at your property.
                        </template>
                      </hb-tooltip>
                    </span>
                  </v-checkbox>
                  <div>
                    <v-select
                      v-if="
                        leaseTemplate.checklist['vehicle'].length &&
                        leaseTemplate.checklist['vehicle'][0].name
                      "
                      :items="getLeaseDocumentsByKey('vehicle')"
                      attach
                      item-text="name"
                      label="Select Document"
                      hide-details
                      class="pa-0 mt-0"
                      single-line
                      item-value="id"
                      v-model="leaseTemplate.checklist['vehicle'][0].document_id"
                    >
                      <template v-slot:append-item>
                        <div id="vehicle" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            v-if="leaseTemplatesLoading"
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-select>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="pa-0 ma-0 mt-3">
              <v-col cols="8" class="ma-0 pa-0">
                <v-card elevation="1" class="pa-4">
                  <v-checkbox
                    :input-value="isDocumentSelected('enroll-coverage')"
                    class="ml-n1 my-0 mb-2"
                    flat
                    hide-details
                    single-line
                    solo
                    @change="
                      updateRequiredDocuments($event, 'enroll-coverage', 'Insurance / Protection Enrollment Document')
                    "
                  >
                    <span slot="label" class="hb-text-night">
                      Insurance / Protection Enrollment
                      <hb-tooltip class="d-inline-flex">
                        <template slot="body">
                          Use an insurance/protection enrollment <br />
                          document to inform tenants that you <br />
                          require coverage to rent a space at your <br />
                          property.
                        </template>
                      </hb-tooltip>
                    </span>
                  </v-checkbox>
                  <div>
                    <v-select
                      v-if="
                        leaseTemplate.checklist['enroll-coverage'].length &&
                        leaseTemplate.checklist['enroll-coverage'][0].name
                      "
                      :items="getLeaseDocumentsByKey('enroll-coverage')"
                      attach
                      item-text="name"
                      label="Select Document"
                      hide-details
                      class="pa-0 mt-0"
                      single-line
                      item-value="id"
                      v-model="leaseTemplate.checklist['enroll-coverage'][0].document_id"
                    >
                      <template v-slot:append-item>
                        <div id="enroll-coverage" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            v-if="leaseTemplatesLoading"
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-select>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="pa-0 ma-0 mt-3">
              <v-col cols="8" class="ma-0 pa-0">
                <v-card elevation="1" class="pa-4">
                  <v-checkbox
                    :input-value="isDocumentSelected('deny-coverage')"
                    class="ml-n1 my-0 mb-2"
                    flat
                    hide-details
                    single-line
                    solo
                    @change="
                      updateRequiredDocuments($event, 'deny-coverage', 'Deny Insurance/Protection Form')
                    "
                  >
                    <span slot="label" class="hb-text-night">
                      Deny Insurance/Protection Form
                      <hb-tooltip class="d-inline-flex">
                        <template slot="body">
                          Tenants can deny the <br />
                          insurance/protection you provide at your <br />
                          property using a deny insurance <br />
                          document.
                        </template>
                      </hb-tooltip>
                    </span>
                  </v-checkbox>
                  <div>
                    <v-select
                      v-if="
                        leaseTemplate.checklist['deny-coverage'].length &&
                        leaseTemplate.checklist['deny-coverage'][0].name
                      "
                      :items="getLeaseDocumentsByKey('deny-coverage')"
                      attach
                      item-text="name"
                      label="Select Document"
                      hide-details
                      class="pa-0 mt-0"
                      single-line
                      item-value="id"
                      v-model="leaseTemplate.checklist['deny-coverage'][0].document_id"
                    >
                      <template v-slot:append-item>
                        <div id="deny-coverage" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            v-if="leaseTemplatesLoading"
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-select>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="pa-0 ma-0 mt-3">
              <v-col cols="8" class="ma-0 pa-0">
                <v-card elevation="1" class="pa-4">
                  <v-checkbox
                    :input-value="isDocumentSelected('military')"
                    class="ml-n1 my-0 mb-2"
                    flat
                    hide-details
                    single-line
                    solo
                    @change="updateRequiredDocuments($event, 'military', 'Military Waiver Document')"
                  >
                    <span slot="label" class="hb-text-night">
                      Military Waiver
                      <hb-tooltip class="d-inline-flex">
                        <template slot="body">
                          A military waiver is used when a tenant or <br />
                          a spouse is a member of the U.S. military <br />
                          and they are signing an SCRA.
                        </template>
                      </hb-tooltip>
                    </span>
                  </v-checkbox>
                  <div>
                    <v-select
                      v-if="
                        leaseTemplate.checklist['military'].length &&
                        leaseTemplate.checklist['military'][0].name
                      "
                      :items="getLeaseDocumentsByKey('military')"
                      attach
                      item-text="name"
                      label="Select Document"
                      hide-details
                      class="pa-0 mt-0"
                      single-line
                      item-value="id"
                      v-model="leaseTemplate.checklist['military'][0].document_id"
                    >
                      <template v-slot:append-item>
                        <div id="military" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            v-if="leaseTemplatesLoading"
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-select>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <div class="pt-3">
              You may also add additional documents to include them to the
              list of materials your tenants must sign at move-in.
            </div>

            <div
              class="pl-0 pt-3"
              v-for="(doc, docIndex) in noTagDocuments"
              :key="docIndex"
            >
              <v-row class="pa-0 ma-0">
                <v-col cols="8" class="ma-0 pa-0">
                  <v-card elevation="1" class="pa-4 pt-0">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="1" class="pa-0 ma-0 pt-4">
                        <hb-icon color="#101318">mdi-check</hb-icon>
                      </v-col>
                      <v-col cols="9" class="pa-0 ma-0">
                        <v-text-field
                          v-model="doc.name"
                          required
                          class="pa-0 ma-0 pt-3"
                          single-line
                          label="Custom Name"
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2" class="pa-0 ma-0 pt-6 pl-7">
                        <hb-icon @click="removeCustomDocument(docIndex)"
                          >mdi-close</hb-icon
                        >
                      </v-col>
                    </v-row>
                    <div>
                      <v-autocomplete
                        :items="getLeaseDocumentsByKey('other')"
                        :filter="customFilter"
                        required
                        v-model="doc.document_id"
                        :label="focused ? 'Search Document' : 'Select Document'"
                        @focus="focused = true"
                        @blur="focused = false"
                        hide-details
                        item-text="name"
                        item-value="id"
                        class="pa-0 mt-0"
                        single-line
                      >
                        <template v-slot:append-item>
                          <div id="other" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                            <v-progress-circular
                              indeterminate
                              color="primary"
                              v-if="leaseTemplatesLoading"
                            ></v-progress-circular>
                          </div>
                        </template>
                      </v-autocomplete>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </div>

            <div class="pt-5" v-if="showAddDocumentOption">
              <a class="hb-link" @click="addCustomDocument"
                >+ Add Document</a
              >
            </div>
          </div>
        </div>

    <v-row class="mr-0 pl-3">
      <v-col class="field-label pa-0 pl-4" cols="3">
        <div class="font-weight-medium pt-2">
          Tenant Rental <br />
          Document <br />
          Library*
          <br /><br />
        </div>
      </v-col>
      <v-col class="font-weight-regular pa-0" cols="9">
        <div v-if="leaseDocumentsLoading" class="pa-4">
          Loading Documents..
           <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <hoverable-edit-field
          v-else
          :activeEditing="activelyEditing == 'tenantRentalDocumentLibrary'"
          :hasPermissionToEdit="isPermissionToEdit"
          name="tenantRentalDocumentLibrary"
        >
          <template slot="editingMode">
            <div>
              <span class="field-description">
                Select the documents you’ll require your tenants to complete at
                move-in, you must select a lease document. Additionally, select
                all other documents that are required based on the tenant’s
                choice. (i.e. Insurance Enrollment Form, AutoPay Enrollment Form,
                etc.)
              </span>

              <div class="pt-3">
                <v-row class="pa-0 ma-0">
                  <v-col cols="8" class="ma-0 pa-0">
                    <v-card class="pa-4" elevation="1">
                      <span>
                        <hb-icon color="#101318">mdi-check</hb-icon> Lease Agreement*
                        <hb-tooltip class="d-inline-flex">
                          <template slot="body">
                            You must include a lease agreement to <br />
                            proceed with the lease configuration <br />
                            process. The lease document you should <br />
                            use depends on the state where you <br />
                            operate.
                          </template>
                        </hb-tooltip>
                      </span>
                        <div>
                          <v-select
                            v-if="leaseTemplate.checklist['lease'].length && leaseTemplate.checklist['lease'][0].name"
                            :items="getLeaseDocumentsByKey('lease')"
                            :error-messages="errors.first('form.lease_document')"
                            id="lease_document"
                            name="lease_document"                  
                            label="Select Document"
                            attach
                            hide-details
                            class="pa-0 mt-0"
                            single-line
                            item-text="name"
                            item-value="id"
                            data-vv-as="Lease Configuration Library"
                            data-vv-scope="form"
                            v-validate="`${ getLeaseDocumentsByKey('lease') ? 'required' : '' }`"
                            required
                            v-model="leaseTemplate.checklist['lease'][0].document_id"
                          >
                          <template v-slot:append-item>
                            <div id="lease" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                v-if="leaseTemplatesLoading"
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-select>
                        </div>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row class="pa-0 ma-0 mt-3">
                  <v-col cols="8" class="ma-0 pa-0">
                    <v-card elevation="1" class="pa-4">
                      <span v-if="leaseTemplate.auto_pay">
                        <hb-icon color="#101318">mdi-check</hb-icon> AutoPay Enrollment*
                      </span>
                      <v-checkbox
                        v-else
                        :input-value="isDocumentSelected('autopay')"
                        class="ml-n1 my-0 mb-2"
                        flat
                        hide-details
                        single-line
                        solo
                        @change="updateRequiredDocuments($event, 'autopay', 'Autopay Enrollment Document')"
                      >
                        <span slot="label" class="hb-text-night">
                          AutoPay Enrollment
                          <hb-tooltip class="d-inline-flex">
                            <template slot="body">
                              Have your tenants sign an autopay <br />
                              enrollment document to authorize you to <br />
                              run their payment method on an <br />
                              automated schedule.
                            </template>
                          </hb-tooltip>
                        </span>
                      </v-checkbox>
                      <div>
                        <v-select
                          v-if="
                            leaseTemplate.checklist['autopay'].length &&
                            leaseTemplate.checklist['autopay'][0].name
                          "
                          :items="getLeaseDocumentsByKey('autopay')"
                          :error-messages="errors.first('form.auto_pay_document')"
                          attach
                          id="auto_pay_document"
                          name="auto_pay_document"      
                          label="Select Document"
                          hide-details
                          item-text="name"
                          class="pa-0 mt-0"
                          single-line
                          item-value="id"
                          data-vv-as="AutoPay Document"
                          data-vv-scope="form"
                          v-validate="`${ leaseTemplate.auto_pay && getLeaseDocumentsByKey('autopay') ? 'required' : '' }`"
                          v-model="leaseTemplate.checklist['autopay'][0].document_id"
                        >
                          <template v-slot:append-item>
                            <div id="autopay" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                v-if="leaseTemplatesLoading"
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0 mt-3">
                  <v-col cols="8" class="ma-0 pa-0">
                    <v-card elevation="1" class="pa-4">
                      <v-checkbox
                        :input-value="isDocumentSelected('vehicle')"
                        class="ml-n1 my-0 mb-2"
                        flat
                        hide-details
                        single-line
                        solo
                        @change="updateRequiredDocuments($event, 'vehicle', 'Vehicle Addendum Document')"
                      >
                        <span slot="label" class="hb-text-night">
                          Vehicle Addendum
                          <hb-tooltip class="d-inline-flex">
                            <template slot="body">
                              A vehicle addendum is used when a <br />
                              tenant informs you that they plan to store <br />
                              a vehicle at your property.
                            </template>
                          </hb-tooltip>
                        </span>
                      </v-checkbox>
                      <div>
                        <v-select
                          v-if="
                            leaseTemplate.checklist['vehicle'].length &&
                            leaseTemplate.checklist['vehicle'][0].name
                          "
                          :items="getLeaseDocumentsByKey('vehicle')"
                          attach
                          item-text="name"
                          label="Select Document"
                          hide-details
                          class="pa-0 mt-0"
                          single-line
                          item-value="id"
                          v-model="leaseTemplate.checklist['vehicle'][0].document_id"
                        >
                          <template v-slot:append-item>
                            <div id="vehicle" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                v-if="leaseTemplatesLoading"
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row class="pa-0 ma-0 mt-3">
                  <v-col cols="8" class="ma-0 pa-0">
                    <v-card elevation="1" class="pa-4">
                      <v-checkbox
                        :input-value="isDocumentSelected('enroll-coverage')"
                        class="ml-n1 my-0 mb-2"
                        flat
                        hide-details
                        single-line
                        solo
                        @change="
                          updateRequiredDocuments($event, 'enroll-coverage', 'Insurance / Protection Enrollment Document')
                        "
                      >
                        <span slot="label" class="hb-text-night">
                          Insurance / Protection Enrollment
                          <hb-tooltip class="d-inline-flex">
                            <template slot="body">
                              Use an insurance/protection enrollment <br />
                              document to inform tenants that you <br />
                              require coverage to rent a space at your <br />
                              property.
                            </template>
                          </hb-tooltip>
                        </span>
                      </v-checkbox>
                      <div>
                        <v-select
                          v-if="
                            leaseTemplate.checklist['enroll-coverage'].length &&
                            leaseTemplate.checklist['enroll-coverage'][0].name
                          "
                          :items="getLeaseDocumentsByKey('enroll-coverage')"
                          attach
                          item-text="name"
                          label="Select Document"
                          hide-details
                          class="pa-0 mt-0"
                          single-line
                          item-value="id"
                          v-model="leaseTemplate.checklist['enroll-coverage'][0].document_id"
                        >
                          <template v-slot:append-item>
                            <div id="enroll-coverage" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                v-if="leaseTemplatesLoading"
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row class="pa-0 ma-0 mt-3">
                  <v-col cols="8" class="ma-0 pa-0">
                    <v-card elevation="1" class="pa-4">
                      <v-checkbox
                        :input-value="isDocumentSelected('deny-coverage')"
                        class="ml-n1 my-0 mb-2"
                        flat
                        hide-details
                        single-line
                        solo
                        @change="
                          updateRequiredDocuments($event, 'deny-coverage', 'Deny Insurance/Protection Form')
                        "
                      >
                        <span slot="label" class="hb-text-night">
                          Deny Insurance/Protection Form
                          <hb-tooltip class="d-inline-flex">
                            <template slot="body">
                              Tenants can deny the <br />
                              insurance/protection you provide at your <br />
                              property using a deny insurance <br />
                              document.
                            </template>
                          </hb-tooltip>
                        </span>
                      </v-checkbox>
                      <div>
                        <v-select
                          v-if="
                            leaseTemplate.checklist['deny-coverage'].length &&
                            leaseTemplate.checklist['deny-coverage'][0].name
                          "
                          :items="getLeaseDocumentsByKey('deny-coverage')"
                          attach
                          item-text="name"
                          label="Select Document"
                          hide-details
                          class="pa-0 mt-0"
                          single-line
                          item-value="id"
                          v-model="leaseTemplate.checklist['deny-coverage'][0].document_id"
                        >
                          <template v-slot:append-item>
                            <div id="deny-coverage" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                v-if="leaseTemplatesLoading"
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row class="pa-0 ma-0 mt-3">
                  <v-col cols="8" class="ma-0 pa-0">
                    <v-card elevation="1" class="pa-4">
                      <v-checkbox
                        :input-value="isDocumentSelected('military')"
                        class="ml-n1 my-0 mb-2"
                        flat
                        hide-details
                        single-line
                        solo
                        @change="updateRequiredDocuments($event, 'military', 'Military Waiver Document')"
                      >
                        <span slot="label" class="hb-text-night">
                          Military Waiver
                          <hb-tooltip class="d-inline-flex">
                            <template slot="body">
                              A military waiver is used when a tenant or <br />
                              a spouse is a member of the U.S. military <br />
                              and they are signing an SCRA.
                            </template>
                          </hb-tooltip>
                        </span>
                      </v-checkbox>
                      <div>
                        <v-select
                          v-if="
                            leaseTemplate.checklist['military'].length &&
                            leaseTemplate.checklist['military'][0].name
                          "
                          :items="getLeaseDocumentsByKey('military')"
                          attach
                          item-text="name"
                          label="Select Document"
                          hide-details
                          class="pa-0 mt-0"
                          single-line
                          item-value="id"
                          v-model="leaseTemplate.checklist['military'][0].document_id"
                        >
                          <template v-slot:append-item>
                            <div id="military" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                v-if="leaseTemplatesLoading"
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>

                <div class="pt-3">
                  You may also add additional documents to include them to the
                  list of materials your tenants must sign at move-in.
                </div>

                <div
                  class="pl-0 pt-3"
                  v-for="(doc, docIndex) in noTagDocuments"
                  :key="docIndex"
                >
                  <v-row class="pa-0 ma-0">
                    <v-col cols="8" class="ma-0 pa-0">
                      <v-card elevation="1" class="pa-4 pt-0">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="1" class="pa-0 ma-0 pt-4">
                            <hb-icon color="#101318">mdi-check</hb-icon>
                          </v-col>
                          <v-col cols="9" class="pa-0 ma-0">
                            <v-text-field
                              v-model="doc.name"
                              required
                              class="pa-0 ma-0 pt-3"
                              single-line
                              label="Custom Name"
                              hide-details
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="2" class="pa-0 ma-0 pt-6 pl-7">
                            <hb-icon @click="removeCustomDocument(docIndex)"
                              >mdi-close</hb-icon
                            >
                          </v-col>
                        </v-row>
                        <div>
                          <v-autocomplete
                            :items="getLeaseDocumentsByKey('other')"
                            :filter="customFilter"
                            required
                            v-model="doc.document_id"
                            :label="focused ? 'Search Document' : 'Select Document'"
                            @focus="focused = true"
                            @blur="focused = false"
                            hide-details
                            item-text="name"
                            item-value="id"
                            class="pa-0 mt-0"
                            single-line
                          >
                            <template v-slot:append-item>
                              <div id="other" v-intersect="getMoreDocumentTemplates" class="d-flex justify-center">
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                  v-if="leaseTemplatesLoading"
                                ></v-progress-circular>
                              </div>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>

                <div class="pt-5" v-if="showAddDocumentOption">
                  <a class="hb-link" @click="addCustomDocument"
                    >+ Add Document</a
                  >
                </div>
              </div>
            </div>
          </template>
          <template slot="readMode" v-if="editMode">
            <div class="hb-text-light pb-11" v-if="!documentFound">
              No Documents Added
            </div>
            <div class="pb-0" v-else>
              <div
                class="pl-0"
                v-for="(checklistItem, checklistIndex) in leaseTemplate.checklist"
                :key="checklistIndex"
              >
                <div v-for="(doc, docIndex) in checklistItem" :key="docIndex">
                  <v-row class="pa-0 ma-0 pt-4">
                    <v-col v-if="doc.document_id" cols="10" class="ma-0 pa-0">
                      <v-card elevation="1" class="pa-4 pt-0">
                        <v-row class="ma-0 pa-0 pt-4">
                          <v-col cols="9" class="pa-0 ma-0">
                            <div>{{ getDocumentNameByTag(doc) }}</div>
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0 pt-4 pb-2">
                          <v-col cols="9" class="pa-0 ma-0">
                            <hb-link color="secondary" @click.stop="">
                              {{ findDocument(doc.document_id, (doc.tag || doc.document_tag)).name || '' }}
                            </hb-link>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </template>
        </hoverable-edit-field>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    -->
  </div>
</template>

<script type="text/babel">
import api from "../../../assets/api.js";
// import HoverableEditField from "../../assets/HoverableEditField.vue";
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import { mapGetters } from "vuex";
import { EventBus } from '../../../EventBus.js';

export default {
  name: "TentantRentalDocumentLibrary",
  data() {
    return {
      showAddDocumentOption: true,
      // keys: lease,autopay,enroll-coverage,deny-coverage,vehicle,military,others
      leaseDocuments: [],
      leaseDocumentsLoading: true,
      leaseTemplatesLoading: false,
      allowScrollRequest: {
        'lease' :    true,
        'autopay' :  true,
        'vehicle': true,
        'enroll-coverage': true,
        'deny-coverage': true,
        'military': true,
        'other': true
      },
      focused: false,
      checklistItemsName: {
        lease : "Lease Agreement",
        'deny-coverage': "Deny Insurance/Protection Form"
      },
      clonedLeaseTemplate: {}
    };
  },
  // components: { HoverableEditField },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    readMode: {
      type: Boolean,
      default: false,
    },
    active: {
      type: String,
      default: "",
    },
    activelyEditing: {
      type: String,
      default: "",
    },
    leaseTemplate: {
      type: Object,
      required: true,
    },
    facilityLevel: {
      type: Boolean,
      default: false
    },
    activeTab: {

    }
  },
  mixins: [ notificationMixin ],
  async created() {
    if(!this.leaseTemplate.checklist["lease"] || !this.leaseTemplate.checklist["lease"].tag) {
      this.leaseTemplate.checklist["lease"].push({
        document_type_id: "",
        document_id: "",
        name: "Lease Agreement",
        description: "",
        require_all: "",
        tag: "lease",
      });
    }

    await this.fetchDocuments();
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    noTagDocuments() {
      const noTagDocuments = this.leaseTemplate.checklist["other"];
      return noTagDocuments;
    },
    isPermissionToEdit() {
      return this.facilityLevel ? false : this.hasPermission('view_edit_templates');
    },
    documentFound() {
      let found = false;
      Object.keys(this.leaseTemplate.checklist).forEach(checklistItem => {
        this.leaseTemplate.checklist[checklistItem].map(doc => {
          if(doc.document_id) {
            found = true;
          }
        });
      });
      return found;
    }
  },
  methods: {
    getDocumentNameByTag(doc) {
      return this.checklistItemsName[doc.document_tag] ? this.checklistItemsName[doc.document_tag] : doc.name;
    },
    async getMoreDocumentTemplates(entries, observer, isIntersecting){
      if (isIntersecting && this.allowScrollRequest[entries[0].target.id] && !this.leaseTemplatesLoading) {
        this.leaseTemplatesLoading = true;
        this.fetchMoreDocuments(entries[0].target.id);
        }
    },
    isDocumentSelected(tag) {
      return this.leaseTemplate.checklist[tag].length && this.leaseTemplate.checklist[tag][0].name
    },
    updateRequiredDocuments(isChecked, checkedDocumentTag, checkedDocumentName) {
      if (isChecked) {
        this.leaseTemplate.checklist[checkedDocumentTag].push({
          document_type_id: "",
          document_id: "",
          name: checkedDocumentName,
          description: "",
          require_all: "",
          tag: checkedDocumentTag,
        });
      } else {
        this.leaseTemplate.checklist[checkedDocumentTag] = [];
        // this.leaseTemplate.checklist = this.leaseTemplate.checklist.filter(checklist => checklist.tag !== value2);
      }
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase().split(" ");
      const searchText = queryText.toLowerCase();
      return textOne[0].indexOf(searchText) > -1;
    },
    addCustomDocument() {
      this.leaseTemplate.checklist["other"].push({
        document_type_id: "",
        document_id: "",
        name: "",
        description: "",
        require_all: "",
        tag: "other",
      });
    },
    removeCustomDocument(index) {
      this.leaseTemplate.checklist["other"].splice(index, 1);
    },
    getLeaseDocumentsByKey(key) {
      const leaseDocuments = this.leaseDocuments && this.leaseDocuments.find((doc) => doc.key === key);
      return leaseDocuments && leaseDocuments.value;
    },
    findDocument(documentId, key) {
      const leaseDocuments = this.getLeaseDocumentsByKey(key);
      const selectedDocument = leaseDocuments && leaseDocuments.find((doc) => doc.id === documentId);
      return selectedDocument || {};
    },
    async fetchDocuments() {
      try {
        this.leaseDocumentsLoading = true;
        let res = await api.get(this, api.DOCUMENT + "?template=true");
        this.leaseDocuments = res.documents;
      } catch (err) {
        // set notification!
        this.showMessageNotification({type: 'error', description: err});
      } finally {
        this.leaseDocumentsLoading = false;
      }
    },
    async fetchMoreDocuments(tag){

        this.leaseTemplatesLoading = true;

        let tagDocuments = this.getLeaseDocumentsByKey(tag);
        let limit = 20;

        if(!tagDocuments || tagDocuments.length < limit){
          this.allowScrollRequest[tag] = false;
          this.leaseTemplatesLoading = false;
          return;
        }

        let offset = tagDocuments.length == 0 ? 1 : Math.ceil(tagDocuments.length / limit) + 1 ;
        let params = `?limit=${limit}&offset=${offset}&type[]=${tag}&template=true`;
    
        api.get(this, api.DOCUMENT.slice(0, -1) + params).then(r => {
            if(r.documents.length == 0) {
              this.allowScrollRequest[tag] = false;
            }else{
              this.allowScrollRequest[tag] =  true;
            }
          
            this.leaseDocuments.forEach(tagDoc => {
              if(tagDoc.key === tag){
                this.tempDocs = [...tagDoc.value, ...r.documents[0].value];
                tagDoc.value = this.tempDocs;
              }
            })
            this.leaseTemplatesLoading = false;
          }).catch(err => {
              this.showMessageNotification({type: 'error', description: err});
              this.leaseTemplatesLoading = false;
          });
      },
    async validateForm() {
      /*if(!this.leaseTemplate.checklist || !this.leaseTemplate.checklist.lease || !this.leaseTemplate.checklist.lease.length || 
      !this.leaseTemplate.checklist.lease[0].document_id) {
        return false
      }*/

      let status = await this.$validator.validateAll("form");
      return status;
    },
    handleEmittedCancel(){
      EventBus.$emit('lease-configuration-revert-to-cached-template', this.clonedLeaseTemplate);
      setTimeout(() => {
        if(this.active === 'Tenant Rental Document Library'){
          this.$emit('setActive', '');
        }
      }, 500);
    },
    handleEmittedSave(){
      this.$emit('save');
    },
    handleEditStart(label){
      setTimeout(() => {
        this.clonedLeaseTemplate = JSON.parse(JSON.stringify(this.leaseTemplate));
      }, 200);
      this.$emit('setActive', label);
    }
  },
  watch: {
    "leaseTemplate.auto_pay"(value) {
      this.updateRequiredDocuments(value, "autopay", "Autopay Enrollment Document");  
    },
    "activeTab"(){
      if(this.activeTab !== 1 && this.active === 'Tenant Rental Document Library'){
        EventBus.$emit('lease-configuration-revert-to-cached-template', this.clonedLeaseTemplate);
      }
    }
  },
};
</script>

<style scoped>
.field-label {
  background-color: #f9fafb;
  color: #101318;
}

.tenant-rental-document-library-v-container-box {
  max-width: 370px;
  background: #fff;
}

.tenant-rental-document-library-edit-margin-top-fix-1px {
  margin-top: 1px;
}

.tenant-rental-document-library-edit-margin-top-fix-3px {
  margin-top: 3px;
}
</style>