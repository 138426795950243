import { render, staticRenderFns } from "./TransferFees.vue?vue&type=template&id=72469898&scoped=true&"
import script from "./TransferFees.vue?vue&type=script&lang=js&"
export * from "./TransferFees.vue?vue&type=script&lang=js&"
import style0 from "./TransferFees.vue?vue&type=style&index=0&id=72469898&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72469898",
  null
  
)

export default component.exports