<template>
  <div>
    <hb-form
      label="Move-In Checklist"
      :description="active === 'Move-In Checklist' || (!editMode && !readMode) ? 'Create a checklist for your manager to review with the tenant upon move-in.' : undefined"
      :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
      :start-open="!editMode && !readMode"
      :hide-actions="!editMode && !readMode"
      :empty="leaseTemplate.checklist && !leaseTemplate.checklist['nameOnly'].length && !readMode"
      :active="active"
      @editStart="handleEditStart('Move-In Checklist')"
      @cancel="handleEmittedCancel"
      @save="handleEmittedSave"
      full
      :last="!readMode"
      :content-position="!readMode ? contentPosition : undefined"
    >
      <template v-slot:display>
        <hb-checkbox
          :value="true"
          readonly
          v-for="(
            checklistItem, checklistIndex
          ) in leaseTemplate.checklist['nameOnly']"
          :key="checklistIndex"
          :class="{ 'mt-3' : checklistIndex > 0 }"
        >
          <template v-slot:label>
            {{ checklistItem.name }}
          </template>
        </hb-checkbox>
      </template>
      <template v-slot:edit>
        <v-row
          v-for="(c, cIndex) in nameOnlyChecklist"
          :key="cIndex"
          no-gutters
          class="pa-0 ma-0"
        >
          <v-col cols="10" no-gutters style="max-width:370px;">
            <HbTextField
              v-model="c.name"
              v-validate="'max:45'"
              placeholder="Enter Checklist Item"
              data-vv-scope="form"
              :data-vv-name="'checklist_item_' + cIndex"
              :data-vv-as="'Checklist Item ' + (cIndex + 1)"
              :error="errors.has('form.checklist_item_' + cIndex)"
              :disabled="c.id && c.id.length > 0"
            />
          </v-col>

          <v-col cols="2" no-gutters class="pl-2">
            <hb-tooltip>
              <template v-slot:item>
                <hb-icon pointer @click="removeCustomDocument(cIndex)">mdi-close</hb-icon>
              </template>
              <template v-slot:body>
                Remove
              </template>
            </hb-tooltip>
          </v-col>
        </v-row>

        <div class="pt-2 pb-1">
          <hb-link @click="addCustomDocument">+ Add Checklist Item</hb-link>
        </div>
      </template>
    </hb-form>

    <!--
    <v-row class="mr-0 pl-3">
      <v-col class="field-label pa-0 pl-4" cols="3">
        <div class="font-weight-medium pt-2">Move-In Checklist</div>
      </v-col>
      <v-col class="font-weight-regular pb-0 pt-0 pa-0" cols="9">
        <hoverable-edit-field
          :activeEditing="activelyEditing == 'moveInChecklist'"
          :hasPermissionToEdit="isPermissionToEdit"
          name="moveInChecklist"
        >
          <template slot="editingMode">
            <div class="field-description">
              Create a checklist for your manager to review with the tenant upon
              move-in.
              <div class="pt-0">
                <div
                  class="pl-0 pt-0"
                  v-for="(c, cIndex) in nameOnlyChecklist"
                  :key="cIndex"
                >
                  <v-row class="pa-0 ma-0">
                    <v-col cols="8" class="ma-0 pa-0">
                      <div>
                        <v-text-field
                          v-model="c.name"
                          single-line
                          label="Checklist Item"
                          clearable
                          hide-details
                        >
                        </v-text-field>
                      </div>
                    </v-col>

                    <v-col cols="2" class="pa-0 ma-0 ml-2 mt-7">
                      <hb-icon @click="removeCustomDocument(cIndex)"
                        >mdi-close</hb-icon
                      >
                    </v-col>
                  </v-row>
                </div>

                <div class="pt-3">
                  <a class="hb-link" @click="addCustomDocument"
                    >+ Add Checklist Item</a
                  >
                </div>
              </div>
            </div>
          </template>
          <template slot="readMode" v-if="editMode">
            <div class="pb-0">
              <div
                class="pl-0"
                v-for="(
                  checklistItem, checklistIndex
                ) in leaseTemplate.checklist['nameOnly']"
                :key="checklistIndex"
              >
                {{ checklistItem.name }}
              </div>
              <div class="hb-text-light" v-if="leaseTemplate.checklist && !leaseTemplate.checklist['nameOnly'].length">
                No Checklist Added 
              </div>
            </div>
          </template>
        </hoverable-edit-field>
      </v-col>
    </v-row>
    -->
  </div>
</template>

<script type="text/babel">
// import HoverableEditField from "../../assets/HoverableEditField.vue";

export default {
  // components: { HoverableEditField },
  name: "MoveInCheckList",
  data() {
    return {
      showAddDocumentOption: true
    };
  },
  computed: {
    nameOnlyChecklist() {
      const otherDocuments = this.leaseTemplate.checklist["nameOnly"];
      return otherDocuments;
    },
  },
  methods: {
    addCustomDocument() {
      this.leaseTemplate.checklist["nameOnly"].push({
        name: "",
      });
    },
    removeCustomDocument(index) {
      this.leaseTemplate.checklist["nameOnly"].splice(index, 1);
    },
    async validateForm() {
      let status = await this.$validator.validateAll("form");
      return status;
    },
    handleEmittedCancel(){
     this.$emit('cancel');
      setTimeout(() => {
        if(this.active === 'Move-In Checklist'){
          this.$emit('setActive', '');
        }
      }, 500);
    },
    handleEmittedSave(){
      this.$emit('save');
    },
    handleEditStart(label){
      this.$emit('setActive', label);
    }
  },
  mounted() {
    this.$validator.extend('checklist', {
      getMessage: field => field + ' is a duplicate value in the checklist',
      validate: value => { 
        let count = 0;
        for (let i = 0; i < this.leaseTemplate.checklist["nameOnly"].length; i++) {
          if (this.leaseTemplate.checklist["nameOnly"][i].name == value) {
            count++;
            if(count >= 2){
              return false;
            }
          }
        }
        return true;
      }
    });
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    readMode: {
      type: Boolean,
      default: false,
    },
    active: {
      type: String,
      default: "",
    },
    activelyEditing: {
      type: String,
      default: "",
    },
    leaseTemplate: {
      type: Object,
      required: true,
    },
    isPermissionToEdit: {
      type: Boolean,
      default: true
    },
    contentPosition: {

    }
  },
};
</script>

<style scoped>
.field-label {
  background-color: #f9fafb;
  color: #101318;
}
</style>